// @flow
import * as bodyPix from '@tensorflow-models/body-pix';
//import {setWasmPaths} from '@tensorflow/tfjs-backend-wasm';
import * as tf from '@tensorflow/tfjs';
import StreamBlurEffect from './StreamBlurEffect';
import '@tensorflow/tfjs-backend-wasm';

function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

function isiOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

function isMobile() {
  return isAndroid() || isiOS();
}

/**
 * Creates a new instance of StreamBlurEffect. This loads the bodyPix model that is used to
 * extract person segmentation.
 *
 * @returns {Promise<StreamBlurEffect>}
 */
export async function createBlurEffect(cb) {
  if (
    !MediaStreamTrack.prototype.getSettings &&
    !MediaStreamTrack.prototype.getConstraints
  ) {
    throw new Error('StreamBlurEffect not supported!');
  }
  let bpModel;
  //'wasm', 'webgl', 'cpu'
  await tf.setBackend('webgl');
  if (isMobile()) {
    bpModel = await bodyPix.load({
      architecture: 'MobileNetV1',
      outputStride: 16,
      multiplier: 0.5,
      quantBytes: 4,
    });
  } else {
    bpModel = await bodyPix.load({
      architecture: 'MobileNetV1',
      outputStride: 8,
      multiplier: 0.75,
      quantBytes: 4,
    });
  }
  window.streamBlurEffect = new StreamBlurEffect(bpModel);
  if (cb) {
    cb();
  }
  return window.streamBlurEffect;
}
