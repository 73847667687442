import axios from 'axios';

export const END_ACTION = 'end';
export const START_ACTION = 'start';
export const CONFIRM_PASSWORD_ACTION = 'checkGuest';
export const GET_MEETING_INFOR = 'getMeetingInfo';
export const CHECK_HOST = 'checkHost';
export const CHECK_CUSTOMER = 'checkCustomer';
export const CHECK_AUTHORITY = 'checkAuthority';
export const MODERATOR = 'moderator';
export const NORMAL = 'normal';
export const GUEST = 'guest';
export const CUSTOMER = 'customer';


export const METTING_STATUS_CANCELED = 'canceled';
export const METTING_STATUS_FINISHED = 'finished';
export const METTING_STATUS_STARTED = 'started';
export const METTING_STATUS_WAITTING = 'waitting';
const url = window.config.authServer || '';

/*
const promise = new Promise(function(resolve, reject) {
                setTimeout(() => resolve(("Done")), 100);
              }).then (() => {
                //response.status === 204
                const data = 
                    {
                        status: 204
                    };
                return data;
              });
            return promise;
*/

export function genPeerId()
{
  const result = axios({
    method: 'post',
    url: `${url}/auth/genPeerId`
  });

  return result;
}

export function logoutMeeting()
{
  const result = axios({
    method: 'post',
    url: `${url}/auth/logout`
  });

  return result;
}


export function checkAuthority(param)
{
  const result = axios({
    method: 'post',
    url: `${url}/meetings/${CHECK_AUTHORITY}`,
    data: param
  });

  return result;
}

export function actionCheckHost(param)
{
  const result = axios({
    method: 'post',
    url: `${url}/meetings/${CHECK_HOST}`,
    data: param
  });

  return result;
}

export function actionCheckCustomer(param)
{
  const result = axios({
    method: 'post',
    url: `${url}/meetings/${CHECK_CUSTOMER}`,
    data: param
  });

  return result;
}

export function actionGetMeetingInfor(param)
{
  const result = axios({
    method: 'post',
    url: `${url}/meetings/${GET_MEETING_INFOR}`,
    data: param
  });

  return result;
}

export function actionConfirmPassword(param)
{
  const result = axios({
    method: 'post',
    url: `${url}/meetings/${CONFIRM_PASSWORD_ACTION}`,
    data: param
  });

  return result;
}

export function actionHostMeeting(param, action)
{
  const result = axios({
    method: 'post',
    url: `${url}/meetings/${action}`,
    data: param
  });

  return result;
}
export function upload(param, config, roomId, type)
{
  const result = axios({
    method: 'post',
    url: `${url}/images/files/upload?id=${roomId}&type=${type}`,
    data: param,
    config
  });

  return result;
}
