import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import NotFound from '../images/page_not_found.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  img: {
    maxWidth: 300,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
    },
  },
  code: {
    fontFamily: 'noto-sans, Helvetica, Arial, sans-serif',
    color: '#222222',
    textAlign: 'center',
    fontSize: '1.7rem',
  },
  text: {
    marginTop: theme.spacing(1),
    fontFamily: 'noto-sans, Helvetica, Arial, sans-serif',
    color: '#7d8597',
    textAlign: 'center',
    fontSize: '1.3rem',
  },
}));

const NotFoundComponent = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img alt="Note found" src={NotFound} className={classes.img} />
      <span className={classes.code}>404</span>
      <span className={classes.text}>
        <FormattedMessage
          id="page.notFound"
          defaultMessage="Sorry, the page you visited does not exist."
        />
      </span>
    </div>
  );
};

export default NotFoundComponent;
