export const DrawTools = {
  ARROW: 'Arrow',
  ARROW_BI: 'ArrowBi',
  LINE_STRAIGHT: 'LineStraight',
  LINE: 'Line',
  OVAL_FILL: 'OvalFill',
  OVAL: 'Oval',
  REACTANGLE_FILL: 'RectangleFill',
  REACTANGLE: 'Rectangle',
  RHOMBUS_FILL: 'RhombusFill',
  RHOMBUS: 'Rhombus'
}

export const Tools = {
  DRAW: 'draw',
  PROPERTY: 'property',
  LINE: 'line',
  SELECT: 'select',
  ERASER: 'eraser',
  TEXT: 'text',
  DELETE: 'delete',
  OPTION: 'option',
  DOWNLOAD: 'download'
}

export const permission = {
  VIEW: 'view',
  EDIT: 'edit'
}

export const FontWhiteboard = {
  BOLD: 'bold',
  ITALIC: 'italic'
}

export const BackgroundColorWb = [
  '#000000',
  '#FFFFFF',
  '#39AC37',
  '#2421D9',
  '#D11924',
  '#2CE7D0',
  '#2E8CFF',
  '#FED02B',
  '#E38D0C',
  '#D537E2',
  '#D59D49',
  '#AEAEAE',
  '#408D68',
  '#6675C2',
  '#56B3B9'
]

export const COLOR_INFO = [
  {
    color: '#000000',
    label: 'black'
  },
  {
    color: '#FFFFFF',
    label: 'white'
  },
  {
    color: '#39AC37',
    label: 'green'
  },
  {
    color: '#2421D9',
    label: 'blue'
  },
  {
    color: '#D11924',
    label: 'red'
  },
  {
    color: '#2CE7D0',
    label: 'turquoise'
  },
  {
    color: '#2E8CFF',
    label: 'dodgerBlue'
  },
  {
    color: '#FED02B',
    label: 'sunglow'
  },
  {
    color: '#E38D0C',
    label: 'gamboge'
  },
  {
    color: '#D537E2',
    label: 'violet'
  },
  {
    color: '#D59D49',
    label: 'robRoy'
  },
  {
    color: '#AEAEAE',
    label: 'darkGray'
  },
  {
    color: '#408D68',
    label: 'viridian'
  },
  {
    color: '#6675C2',
    label: 'havelockBlue'
  },
  {
    color: '#56B3B9',
    label: 'puertoRico'
  }
]

export const LineWidth = {
  SMALL: 1,
  MEDIUM: 5,
  LARGE: 10,
  EXTRA: 15
}

let fontSize = []
for (let index = 16; index < 50; index++) {
  fontSize.push(index)
}

export const FontSize = fontSize
export const FONT_SIZE_LIST = fontSize

export const actionSyncObject = {
  ADD_OBJECT: 'addObject',
  ADD_NEW_PAGE: 'addNewPage',
  DELETE_OBJECT: 'deleteObject',
  DELETE_PAGE: 'deletePage',
  DELETE_ALL_OBJECT_ON_PAGE: 'deleteAllObjectOnPage',
  MODIFY_OBJECT: 'modifiedObject',
  REDO_OBJECT: 'redoObject',
  UNDO_OBJECT: 'undoObject',
  IMPORT_JSON_DATA: 'importJsonData'
}

export const actionOnPageHeader = {
  NEXT_PAGE: 'nextslide',
  PREVIOUS_PAGE: 'previousslide',
  DELETE_PAGE: 'deleteslide',
  NEW_NEXT_PAGE: 'newslide',
  NOTHING: ''
}

export const auditTrail = [
  'objectId',
  'createdAt',
  'createdBy',
  'modifiedAt',
  'modifiedBy'
]

export const GROUP_TYPE_OBJECT = {
  DRAW: {
    PATH: 'path',
    LINE: 'line',
    RECT: 'rect',
    ELLIPSE: 'ellipse',
    GROUP: 'group'
  },
  TEXT: {
    ITEXT: 'i-text'
  }
}
