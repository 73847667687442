import {  MODERATOR, CUSTOMER} from './utils/apiUtils';
import imageCompression from 'browser-image-compression';
/**
 * Create a function which will call the callback function
 * after the given amount of milliseconds has passed since
 * the last time the callback function was called.
 */
export const idle = (callback, delay) =>
{
	let handle;

	return () =>
	{
		if (handle)
		{
			clearTimeout(handle);
		}

		handle = setTimeout(callback, delay);
	};
};

/**
 * Error produced when a socket request has a timeout.
 */
export class SocketTimeoutError extends Error
{
	constructor(message)
	{
		super(message);

		this.name = 'SocketTimeoutError';

		if (Error.hasOwnProperty('captureStackTrace')) // Just in V8.
			Error.captureStackTrace(this, SocketTimeoutError);
		else
			this.stack = (new Error(message)).stack;
	}
}

export function sendAuthenticationRequest(client_id, scope)
{
	var authorization_request = window.config.clientAuth.authServer;
	authorization_request += "?response_type=code";
	authorization_request += "&scope=" + encodeURIComponent(scope);
	authorization_request += "&client_id=" + encodeURIComponent(client_id);
	authorization_request += "&redirect_uri=" + encodeURIComponent(window.config.clientAuth.redirectUri);
	// nonce
	var nonce = Array.from(window.crypto.getRandomValues(new Uint32Array(1)), t => t.toString(11)).join("");
	authorization_request += "&nonce=" + encodeURIComponent(nonce);
	window.localStorage.setItem("nonce", nonce);
	// code_verifier
	var code_verifier = Array.from(window.crypto.getRandomValues(new Uint32Array(4)), t => t.toString(32)).join("");
	window.localStorage.setItem("code_verifier", code_verifier);
	// code_challenge
	var code_challenge = code_verifier;
	authorization_request += "&code_challenge=" + encodeURIComponent(code_challenge); 
	authorization_request += "&code_challenge_method=S256"; 
	window.location.assign(authorization_request);
}

export function invokeTokenRequest(configuration, client_id, client_secret, code) {
	var authorizationCode = "Basic " + btoa(client_id + ":" + client_secret);
	var token_endpoint =  window.config.clientAuth.tokenServer;
	var headers = { "Content-Type": "application/x-www-form-urlencoded",  "Authorization": authorizationCode}; 
	var body = "grant_type=authorization_code";
	body += "&code=" + encodeURIComponent(code);
	body += "&redirect_uri=" + encodeURIComponent(window.config.clientAuth.redirectUri);
	var code_verifier = window.localStorage.getItem("code_verifier");
	if (code_verifier) {
		body += "&code_verifier=" + encodeURIComponent(code_verifier);
	} 
	
	return fetch(token_endpoint, { mode: "cors",  method: "POST", headers: headers, body: body })
		.then(response => response.ok
			? response.json()
			: Promise.reject(response)
		)
		.then(function (response) {
			console.log("invokeTokenRequest tokenResponse " + JSON.stringify(response));
			return Promise.resolve(response);
		})
		.catch(function (error) {
			console.error("invokeTokenRequest error " + error);
			return Promise.reject(error);
		})
		.finally(() => { 
			window.localStorage.removeItem("code_verifier"); 
		});
}

export function invokeProfile(idToken) {
	var base64Url = idToken.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	window.localStorage.setItem('userProfile', jsonPayload);
	return JSON.parse(jsonPayload);
}

export function goTo(userType, roomId) {
	//location.origin
	
	let type;
	if (userType === MODERATOR)
	{
		type = 's';
	}
	else if (userType === CUSTOMER)
	{
		type = 'j';
	}
	if (type)
	{
		window.location.assign(`${window.location.origin}/conference/${type}/${roomId}`);
	}
}

export async function resizeImageFn(file)
{
  const imageFile = file;
  const options = {
	maxSizeMB: 1,
	maxWidthOrHeight: 1000,
	useWebWorker: true,
	fileType: 'png'
  };
  const compressedFile = await imageCompression(imageFile, options);
  return compressedFile;
};

export async function getBase64ImageFromUrl(imageUrl) {
	var res = await fetch(imageUrl);
	var blob = await res.blob();

	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.addEventListener("load", function () {
			resolve(reader.result);
		}, false);

		reader.onerror = () => {
			return reject(this);
		};
		reader.readAsDataURL(blob);
	})
}

