import hark from 'hark';
import RecordRTC from 'recordrtc';
import Logger from './Logger';
import { getSignalingUrl } from './urlFactory';
import { SocketTimeoutError } from './utils';
import * as requestActions from './actions/requestActions';
import * as meActions from './actions/meActions';
import * as roomActions from './actions/roomActions';
import * as peerActions from './actions/peerActions';
import * as peerVolumeActions from './actions/peerVolumeActions';
import * as settingsActions from './actions/settingsActions';
import * as chatActions from './actions/chatActions';
import * as whiteboardActions from './actions/whiteboardActions';
import * as fileActions from './actions/fileActions';
import * as lobbyPeerActions from './actions/lobbyPeerActions';
import * as consumerActions from './actions/consumerActions';
import * as producerActions from './actions/producerActions';
import * as notificationActions from './actions/notificationActions';
import * as transportActions from './actions/transportActions';
import RecordingVideoStreamMerger from './components/VideoMerger';
import { LayoutMode } from './constants/constants'
import {createBlurEffect} from './components/blur';
import {genTurn} from './utils/turn';
import notify from './assets/sounds/notify.mp3';
import {logoutMeeting, NORMAL} from './utils/apiUtils';
import {actionSyncObject as _actionSyncObject} from './components/Whiteboard/Constants';
import {
	actionHostMeeting,
	END_ACTION
  
} from './utils/clientApiUtils';

const clientAuthen = window.config.clientAuthen || false;
let createTorrent;

let WebTorrent;

let saveAs;

let mediasoupClient;

let io;

let ScreenShare;

let Spotlights;
//startTime, 
let then, now, fpsInterval, elapsed;

let requestTimeout,
	transportOptions,
	lastN,
	mobileLastN;

if (process.env.NODE_ENV !== 'test')
{
	({
		requestTimeout  = 20000,
		transportOptions,
		lastN,
		mobileLastN,
	} = window.config);
}

const logger = new Logger('RoomClient');

const ROOM_OPTIONS =
{
	requestTimeout   : requestTimeout,
	transportOptions : transportOptions
};

const VIDEO_CONSTRAINS =
{
	'low' :
	{
		width       : { ideal: 320 },
		aspectRatio : 1.334
	},
	'medium' :
	{
		width       : { ideal: 640 },
		aspectRatio : 1.334
	},
	'high' :
	{
		width       : { ideal: 1280 },
		aspectRatio : 1.778
	},
	'veryhigh' :
	{
		width       : { ideal: 1920 },
		aspectRatio : 1.778
	},
	'ultra' :
	{
		width       : { ideal: 3840 },
		aspectRatio : 1.5
	}
};

const PC_PROPRIETARY_CONSTRAINTS =
{
	optional : [ { googDscp: true } ]
};

const VIDEO_SIMULCAST_ENCODINGS =
[
	{ scaleResolutionDownBy: 4, maxBitrate: 500000 },
	{ scaleResolutionDownBy: 2, maxBitrate: 1000000 },
	{ scaleResolutionDownBy: 1, maxBitrate: 5000000 }
];

// Used for VP9 webcam video.
const VIDEO_KSVC_ENCODINGS =
[
	{ scalabilityMode: 'S3T3_KEY' }
];

// Used for VP9 desktop sharing.
const VIDEO_SVC_ENCODINGS =
[
	{ scalabilityMode: 'S3T3', dtx: true }
];

let store;

let intl;

let options;

let audioOptions;

let isSupportRecord = false;

const AUDIO_MERGER_KEY = 'AUDIO_KEY';

let streamBlurEffect;


export default class RoomClient
{
	/**
	 * @param  {Object} data
	 * @param  {Object} data.store - The Redux store.
	 * @param  {Object} data.intl - react-intl object
	 */
	static async init(data)
	{
		store = data.store;
		intl = data.intl;
		isSupportRecord = data.recordInfor.flag;
		options = data.recordInfor.options;
		audioOptions = data.recordInfor.audioOptions;
		if (window.config.blurEffect)
		{
			if (!window.streamBlurEffect)
			{
				streamBlurEffect = await createBlurEffect();
			}
			else if (!window.streamBlurEffect)
			{
				streamBlurEffect = window.streamBlurEffect;
			}
		}
	}

	constructor(
		{
			peerId,
			accessCode,
			device,
			produce,
			forceTcp,
			displayName,
			muted,
			basePath,
			userType,
			svc,
			roomType,
			joinWithVideo,
			joinWithAudio,
			//svc = 'L3T3'
		} = {})
	{
		if (!peerId)
			throw new Error('Missing peerId');
		else if (!device)
			throw new Error('Missing device');

		logger.debug(
			'constructor() [peerId: "%s", device: "%s", produce: "%s", forceTcp: "%s", displayName ""]',
			peerId, device.flag, produce, forceTcp, displayName);
		this._roomType = roomType;
		this._handlerName = null;
		this._merger = null;
		//this._mediaRecorder = null;
		this._signalingUrl = null;
		// Extra videos being produced
		this._peerAudioMap = new Map();
		this.isSupportRecord = isSupportRecord;
		// Closed flag.
		this._closed = false;

		// Whether we should produce.
		this._produce = produce;

		// Whether we force TCP
		this._forceTcp = forceTcp;

		// URL basepath
		this._basePath = basePath;

		// Use displayName
		if (displayName)
			store.dispatch(settingsActions.setDisplayName(displayName));

		this._tracker = 'wss://track.akameet.tech:443';

		// Torrent support
		this._torrentSupport = null;

		// Whether simulcast should be used.
		this._useSimulcast = false;

		if ('simulcast' in window.config)
			this._useSimulcast = window.config.simulcast;

		// Whether simulcast should be used for sharing
		this._useSharingSimulcast = false;

		if ('simulcastSharing' in window.config)
			this._useSharingSimulcast = window.config.simulcastSharing;

		this._muted = muted;

		// This device
		this._device = device;

		// My peer name.
		this._peerId = peerId;

		this._activeSpeakerPeerId = peerId;

		this._currentMode = store.getState().room.mode;

		// Access code
		this._accessCode = accessCode;

		// Alert sound
		this._soundAlert = new Audio(notify);

		// Socket.io peer connection
		this._signalingSocket = null;

		// The room ID
		this._roomId = null;

		// mediasoup-client Device instance.
		// @type {mediasoupClient.Device}
		this._mediasoupDevice = null;

		// Put the browser info into state
		store.dispatch(meActions.setBrowser(device));

		// Our WebTorrent client
		this._webTorrent = null;

		// Max spotlights
		if (device.platform === 'desktop')
			this._maxSpotlights = lastN;
		else
			this._maxSpotlights = mobileLastN;

		store.dispatch(
			settingsActions.setLastN(this._maxSpotlights));

		// Manager of spotlight
		this._spotlights = null;

		// Transport for sending.
		this._sendTransport = null;

		// Transport for receiving.
		this._recvTransport = null;

		// Local mic mediasoup Producer.
		this._micProducer = null;

		// Local mic hark
		this._hark = null;

		// Local MediaStream for hark
		this._harkStream = null;

		// Local webcam mediasoup Producer.
		this._webcamProducer = null;

		// Extra videos being produced
		this._extraVideoProducers = new Map();

		// Map of webcam MediaDeviceInfos indexed by deviceId.
		// @type {Map<String, MediaDeviceInfos>}
		this._webcams = {};

		this._audioDevices = {};

		this._audioOutputDevices = {};

		// mediasoup Consumers.
		// @type {Map<String, mediasoupClient.Consumer>}
		this._consumers = new Map();

		this._screenSharing = null;

		this._dummyProducer = null;
		this._screenSharingProducer = null;

		this._startKeyListener();

		this._startDevicesListener();

		this._startRecord = false;
		
		const iMeetingInfor = 
				{
					id: this._peerId,
					peerId: this._peerId,
					roomName: this._peerId,
					members: [],
					status:'Started',
					joinUrl: window.location.href,
					userType: null
				};

		this.dataInit = iMeetingInfor;		
		this.userType = userType;
		this._store = store;		

		// Set custom SVC scalability mode.
		if (svc)
		{
			VIDEO_KSVC_ENCODINGS[0].scalabilityMode = `${svc}_KEY`;
			VIDEO_SVC_ENCODINGS[0].scalabilityMode = svc;
		}
		this._audioRecord = null;
		this._videoRecord = null;
		this._isClosing = false;
		this._joinWithVideo = joinWithVideo || false;
		this._joinWithAudio = joinWithAudio || false;
		window.addEventListener("beforeunload", this._onUnload.bind(this));
		this._authServer = '';
		if (window.config.authServer)
		{
			this._authServer = window.config.authServer;
		}

		this._login = false;
		this._videoStream = null;
		this._emptyCanvas = null;
		this._dummyStream = null;
		this._lastActiveSpeaker = null;
	}

	getVideoStream()
	{
		return this._videoStream;
	}
	device()
	{
		return this._device;
	}
	
	isLogin()
	{
		return this._login;
	}

	setLogin()
	{
		this._login = true;
	}
	setRoomId(roomId)
	{
		this._roomId = roomId;
	}
	roomType()
	{
		return this._roomType;
	}

	getPeerId()
	{
		return this._peerId;
	}

	setPeerId(peerId)
	{
		return this._peerId = peerId;
	}

	close()
	{
		if (this._closed)
			return;
		
		localStorage.removeItem('localPeerId');

		if (this._startRecord) {
			this._isClosing = true;
			this._stopRecording();
			return;
		}
		window.removeEventListener("beforeunload", this._onUnload.bind(this));

		this._closed = true;

		logger.debug('close()');

		this._signalingSocket.close();

		// Close mediasoup Transports.
		if (this._sendTransport)
			this._sendTransport.close();

		if (this._recvTransport)
			this._recvTransport.close();

		store.dispatch(roomActions.setRoomState('closed'));

		if (streamBlurEffect){
			streamBlurEffect.stopEffect();
		}
		if (!this._reconect)
		{
			window.location =  window.config.baseUrl || '/' ;
		}
	}

	_startKeyListener()
	{
		// Add keydown event listener on document
		document.addEventListener('keydown', (event) =>
		{
			if (event.repeat) return;
			const key = String.fromCharCode(event.which);

			const source = event.target;

			const exclude = [ 'input', 'textarea' ];

			if (exclude.indexOf(source.tagName.toLowerCase()) === -1)
			{
				logger.debug('keyDown() [key:"%s"]', key);

				switch (key)
				{
					// case String.fromCharCode(37):
					// {
					// 	const newPeerId = this._spotlights.getPrevAsSelected(
					// 		store.getState().room.selectedPeerId);
						
					// 	const { sharescreenId } = store.getState().room;
					// 	if (sharescreenId === null)
					// 	{
					// 		if (newPeerId) this.setSelectedPeer(newPeerId);
					// 		break;
					// 	}
					// 	break;
					// }
					// case String.fromCharCode(39):
					// {
					// 	const newPeerId = this._spotlights.getNextAsSelected(
					// 		store.getState().room.selectedPeerId);

					// 	const { sharescreenId } = store.getState().room;
					// 	if (sharescreenId === null)
					// 	{
					// 		if (newPeerId) this.setSelectedPeer(newPeerId);
					// 		break;
					// 	}
					// 	break;
					// }
					case 'A': // Activate advanced mode
					{
						store.dispatch(settingsActions.toggleAdvancedMode());
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.toggleAdvancedMode',
									defaultMessage : 'Toggled advanced mode'
								})
							}));
						break;
					}

					case '1': // Set democratic view
					{
						this.changeDisplayMode(LayoutMode.DEMOCRATIC);
						break;
					}

					case '2': // Set filmstrip view
					{
						this.changeDisplayMode(LayoutMode.FILMSTRIP);
						break;
					}

					case ' ': // Push To Talk start
					{
						if (this._micProducer)
						{
							if (this._micProducer.paused)
							{
								this.unmuteMic();
							}
						}

						break;
					}
					case 'M': // Toggle microphone
					{
						if (this._micProducer)
						{
							if (!this._micProducer.paused)
							{
								this.muteMic();

								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'devices.microphoneMute',
											defaultMessage : 'Muted your microphone'
										})
									}));
							}
							else
							{
								this.unmuteMic();

								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'devices.microphoneUnMute',
											defaultMessage : 'Unmuted your microphone'
										})
									}));
							}
						}
						else
						{
							this.updateMic({ start: true });

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'devices.microphoneEnable',
										defaultMessage : 'Enabled your microphone'
									})
								}));
						}

						break;
					}

					case 'V': // Toggle video
					{
						if (this._webcamProducer)
							this.disableWebcam();
						else
							this.updateWebcam({ start: true });

						break;
					}

					case 'H': // Open help dialog
					{
						store.dispatch(roomActions.setHelpOpen(true));

						break;
					}

					default:
					{
						break;
					}
				}
			}
		});
		document.addEventListener('keyup', (event) =>
		{
			const key = String.fromCharCode(event.which);

			const source = event.target;

			const exclude = [ 'input', 'textarea' ];

			if (exclude.indexOf(source.tagName.toLowerCase()) === -1)
			{
				logger.debug('keyUp() [key:"%s"]', key);

				switch (key)
				{
					case ' ': // Push To Talk stop
					{
						if (this._micProducer)
						{
							if (!this._micProducer.paused)
							{
								this.muteMic();
							}
						}

						break;
					}
					default:
					{
						break;
					}
				}
			}
			event.preventDefault();
		}, true);

	}

	_startDevicesListener()
	{
		navigator.mediaDevices.addEventListener('devicechange', async () =>
		{
			logger.debug('_startDevicesListener() | navigator.mediaDevices.ondevicechange');

			await this._updateAudioDevices();
			await this._updateWebcams();
			await this._updateAudioOutputDevices();

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'devices.devicesChanged',
						defaultMessage : 'Your devices changed, configure your devices in the settings dialog'
					})
				}));
		});
	}

	login()
	{
		const url = `${this._authServer}/auth/login?roomId=${this._roomId}&peerId=${this._peerId}&userType=${this.userType}&redirectUri=${window.location.href}`;
		window.location.assign(url);
	}

	deleteAllCookies() {
		var allCookies = document.cookie.split(';'); 
		// The "expire" attribute of every cookie is  
                // Set to "Thu, 01 Jan 1970 00:00:00 GMT" 
		for (var i = 0; i < allCookies.length; i++) 
			document.cookie = allCookies[i] + "=;expires=" 
			+ new Date(0).toUTCString(); 
	}

	async logout()
	{
		await logoutMeeting();
		this.deleteAllCookies();
		this._login = false;
		this.close();
	}

	receiveLoginChildWindow(data)
	{
		logger.debug('receiveFromChildWindow() | [data:"%o"]', data);

		const { displayName, picture } = data;
		store.dispatch(settingsActions.setDisplayName(displayName));
		store.dispatch(meActions.setPicture(picture));

		store.dispatch(meActions.loggedIn(true));

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'room.loggedIn',
					defaultMessage : 'You are logged in'
				})
			}));
		this._login = true;
	}

	receiveLogoutChildWindow()
	{
		logger.debug('receiveLogoutChildWindow()');

		store.dispatch(meActions.setPicture(null));

		store.dispatch(meActions.loggedIn(false));

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'room.loggedOut',
					defaultMessage : 'You are logged out'
				})
			}));
		this._login = false;
		this.close();
	}

	_soundNotification()
	{
		const { notificationSounds } = store.getState().settings;

		if (notificationSounds)
		{
			const alertPromise = this._soundAlert.play();

			if (alertPromise !== undefined)
			{
				alertPromise
					.then()
					.catch((error) =>
					{
						logger.error('_soundAlert.play() [error:"%o"]', error);
					});
			}
		}
	}

	timeoutCallback(callback)
	{
		let called = false;

		const interval = setTimeout(
			() =>
			{
				if (called)
					return;
				called = true;
				callback(new SocketTimeoutError('Request timed out'));
			},
			ROOM_OPTIONS.requestTimeout
		);

		return (...args) =>
		{
			if (called)
				return;
			called = true;
			clearTimeout(interval);

			callback(...args);
		};
	}

	_sendRequest(method, data)
	{
		return new Promise((resolve, reject) =>
		{
			if (!this._signalingSocket)
			{
				reject('No socket connection');
			}
			else
			{
				this._signalingSocket.emit(
					'request',
					{ method, data },
					this.timeoutCallback((err, response) =>
					{
						if (err)
							reject(err);
						else
							resolve(response);
					})
				);
			}
		});
	}

	async getTransportStats()
	{
		try
		{
			if (this._recvTransport)
			{
				logger.debug('getTransportStats() - recv [transportId: "%s"]', this._recvTransport.id);

				const recv = await this.sendRequest('getTransportStats', { transportId: this._recvTransport.id });

				store.dispatch(
					transportActions.addTransportStats(recv, 'recv'));
			}

			if (this._sendTransport)
			{
				logger.debug('getTransportStats() - send [transportId: "%s"]', this._sendTransport.id);

				const send = await this.sendRequest('getTransportStats', { transportId: this._sendTransport.id });

				store.dispatch(
					transportActions.addTransportStats(send, 'send'));
			}
		}
		catch (error)
		{
			logger.error('getTransportStats() [error:"%o"]', error);
		}
	}

	async sendRequest(method, data)
	{
		logger.debug('sendRequest() [method:"%s", data:"%o"]', method, data);

		const {
			requestRetries = 3
		} = window.config;

		for (let tries = 0; tries < requestRetries; tries++)
		{
			try
			{
				return await this._sendRequest(method, data);
			}
			catch (error)
			{
				if (
					error instanceof SocketTimeoutError &&
					tries < requestRetries
				)
					logger.warn('sendRequest() | timeout, retrying [attempt:"%s"]', tries);
				else
					throw error;
			}
		}
	}

	async changeDisplayName(displayName)
	{
		logger.debug('changeDisplayName() [displayName:"%s"]', displayName);

		if (!displayName)
			displayName = 'Guest';

		store.dispatch(
			meActions.setDisplayNameInProgress(true));

		try
		{
			await this.sendRequest('changeDisplayName', { displayName });

			store.dispatch(settingsActions.setDisplayName(displayName));

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.changedDisplayName',
						defaultMessage : 'Your display name changed to {displayName}'
					}, {
						displayName
					})
				}));
		}
		catch (error)
		{
			logger.error('changeDisplayName() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.changeDisplayNameError',
						defaultMessage : 'An error occurred while changing your display name'
					})
				}));
		}

		store.dispatch(
			meActions.setDisplayNameInProgress(false));
	}

	async changePicture(picture)
	{
		logger.debug('changePicture() [picture: "%s"]', picture);

		try
		{
			await this.sendRequest('changePicture', { picture });
		}
		catch (error)
		{
			logger.error('changePicture() [error:"%o"]', error);
		}
	}

	async sendChatMessage(chatMessage)
	{
		logger.debug('sendChatMessage() [chatMessage:"%s"]', chatMessage);

		try
		{
			store.dispatch(
				chatActions.addUserMessage(chatMessage.text));

			await this.sendRequest('chatMessage', { chatMessage });
		}
		catch (error)
		{
			logger.error('sendChatMessage() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.chatError',
						defaultMessage : 'Unable to send chat message'
					})
				}));
		}
	}
	/* whiteboard - ducnv3 */
	async sendWhiteboardObject(slideIndex, currentSlide, whiteBoardObject, actionSyncObject, canvasProperty) {
		try {
			await this.sendRequest('whiteBoardData', {
				slideIndex: slideIndex,
				slideChanged: currentSlide,
				object: whiteBoardObject,
				actionSyncObject: actionSyncObject,
				canvasProperty: canvasProperty
			});
		} catch (error) {
			console.log('Unable to send whiteboard to server');
		}
	}
	
	async setWhiteBoardProperty(canvasProperty) {
		try {
			await this.sendRequest('setWhiteBoardProperty', {
				canvasProperty: canvasProperty
			});
		} catch (error) {
			console.log('Unable to set property whiteboard from server');
		}
	}

	async getWhiteboard() {
		try {
			return await this.sendRequest('getWhiteBoardData');
		} catch (error) {
			console.log('Unable to get data whiteboard from server');
		}
	}

	async setPermisionWhiteboard(peerId, role) {
		try {
			return await this.sendRequest('setPermisionWhiteboard', {
				peerId,
				role
			});
		} catch (error) {
			console.log('Unable to set permission whiteboard from server');
		}
	}

	saveFile(file)
	{
		file.getBlob((err, blob) =>
		{
			if (err)
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.saveFileError',
							defaultMessage : 'Unable to save file'
						})
					}));

				return;
			}

			saveAs(blob, file.name);
		});
	}

	handleDownload(magnetUri)
	{
		store.dispatch(
			fileActions.setFileActive(magnetUri));

		const existingTorrent = this._webTorrent.get(magnetUri);

		if (existingTorrent)
		{
			// Never add duplicate torrents, use the existing one instead.
			this._handleTorrent(existingTorrent);

			return;
		}

		this._webTorrent.add(magnetUri, this._handleTorrent);
	}

	_handleTorrent(torrent)
	{
		// Torrent already done, this can happen if the
		// same file was sent multiple times.
		if (torrent.progress === 1)
		{
			store.dispatch(
				fileActions.setFileDone(
					torrent.magnetURI,
					torrent.files
				));

			return;
		}

		let lastMove = 0;

		torrent.on('download', () =>
		{
			if (Date.now() - lastMove > 1000)
			{
				store.dispatch(
					fileActions.setFileProgress(
						torrent.magnetURI,
						torrent.progress
					));

				lastMove = Date.now();
			}
		});

		torrent.on('done', () =>
		{
			store.dispatch(
				fileActions.setFileDone(
					torrent.magnetURI,
					torrent.files
				));
		});
	}

	async shareFiles(files)
	{
		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'filesharing.startingFileShare',
					defaultMessage : 'Attempting to share file'
				})
			}));

		createTorrent(files, (err, torrent) =>
		{
			if (err)
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.unableToShare',
							defaultMessage : 'Unable to share file'
						})
					}));

				return;
			}

			const existingTorrent = this._webTorrent.get(torrent);

			if (existingTorrent)
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'filesharing.successfulFileShare',
							defaultMessage : 'File successfully shared'
						})
					}));

				store.dispatch(fileActions.addFile(
					this._peerId,
					existingTorrent.magnetURI
				));

				this._sendFile(existingTorrent.magnetURI);

				return;
			}

			this._webTorrent.seed(
				files,
				{ announceList: [ [ this._tracker ] ] },
				(newTorrent) =>
				{
					store.dispatch(requestActions.notify(
						{
							text : intl.formatMessage({
								id             : 'filesharing.successfulFileShare',
								defaultMessage : 'File successfully shared'
							})
						}));

					store.dispatch(fileActions.addFile(
						this._peerId,
						newTorrent.magnetURI
					));

					this._sendFile(newTorrent.magnetURI);
				});
		});
	}

	// { file, name, picture }
	async _sendFile(magnetUri)
	{
		logger.debug('sendFile() [magnetUri:"%o"]', magnetUri);

		try
		{
			await this.sendRequest('sendFile', { magnetUri });
		}
		catch (error)
		{
			logger.error('sendFile() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'filesharing.unableToShare',
						defaultMessage : 'Unable to share file'
					})
				}));
		}
	}

	async muteMic()
	{
		logger.debug('muteMic()');

		this._micProducer.pause();

		try
		{
			await this.sendRequest(
				'pauseProducer', { producerId: this._micProducer.id });

			store.dispatch(
				producerActions.setProducerPaused(this._micProducer.id));
		}
		catch (error)
		{
			logger.error('muteMic() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.microphoneMuteError',
						defaultMessage : 'Unable to mute your microphone'
					})
				}));
		}
	}

	async unmuteMic()
	{
		logger.debug('unmuteMic()');

		if (!this._micProducer)
		{
			this.updateMic({ start: true });
		}
		else
		{
			this._micProducer.resume();

			try
			{
				await this.sendRequest(
					'resumeProducer', { producerId: this._micProducer.id });

				store.dispatch(
					producerActions.setProducerResumed(this._micProducer.id));
			}
			catch (error)
			{
				logger.error('unmuteMic() [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'devices.microphoneUnMuteError',
							defaultMessage : 'Unable to unmute your microphone'
						})
					}));
			}
		}
	}

	changeMaxSpotlights(maxSpotlights)
	{
		this._spotlights.maxSpotlights = maxSpotlights;

		store.dispatch(
			settingsActions.setLastN(maxSpotlights));
	}

	// Updated consumers based on spotlights
	async updateSpotlights(spotlights)
	{
		logger.debug('updateSpotlights()');

		try
		{
			for (const consumer of this._consumers.values())
			{
				if (consumer.kind === 'video')
				{
					if (spotlights.includes(consumer.appData.peerId))
						await this._resumeConsumer(consumer);
					else
						await this._pauseConsumer(consumer);
				}
			}
		}
		catch (error)
		{
			logger.error('updateSpotlights() [error:"%o"]', error);
		}
	}

	disconnectLocalHark()
	{
		logger.debug('disconnectLocalHark()');

		if (this._harkStream != null)
		{
			let [ track ] = this._harkStream.getAudioTracks();

			track.stop();
			track = null;

			this._harkStream = null;
		}

		if (this._hark != null)
			this._hark.stop();
	}

	connectLocalHark(track)
	{
		logger.debug('connectLocalHark() [track:"%o"]', track);

		this._harkStream = new MediaStream();

		const newTrack = track.clone();

		this._harkStream.addTrack(newTrack);

		newTrack.enabled = true;

		this._hark = hark(this._harkStream,
			{
				play      : false,
				interval  : 10,
				threshold : store.getState().settings.noiseThreshold,
				history   : 100
			});

		this._hark.lastVolume = -100;

		this._hark.on('volume_change', (volume) =>
		{
			volume = Math.round(volume);

			if (this._micProducer && (volume !== Math.round(this._hark.lastVolume)))
			{
				if (volume < this._hark.lastVolume)
				{
					volume =
						this._hark.lastVolume -
						Math.pow(
							(volume - this._hark.lastVolume) /
							(100 + this._hark.lastVolume)
							, 4
						) * 2;
				}

				this._hark.lastVolume = volume;

				store.dispatch(peerVolumeActions.setPeerVolume(this._peerId, volume));
			}
		});

		this._hark.on('speaking', () =>
		{
			store.dispatch(meActions.setIsSpeaking(true));

			if (
				(store.getState().settings.voiceActivatedUnmute ||
				store.getState().me.isAutoMuted) &&
				this._micProducer &&
				this._micProducer.paused
			)
				this._micProducer.resume();

			store.dispatch(meActions.setAutoMuted(false)); // sanity action
		});

		this._hark.on('stopped_speaking', () =>
		{
			store.dispatch(meActions.setIsSpeaking(false));

			if (
				store.getState().settings.voiceActivatedUnmute &&
				this._micProducer &&
				!this._micProducer.paused
			)
			{
				this._micProducer.pause();

				store.dispatch(meActions.setAutoMuted(true));
			}
		});
	}

	async changeAudioOutputDevice(deviceId)
	{
		logger.debug('changeAudioOutputDevice() [deviceId:"%s"]', deviceId);

		store.dispatch(
			meActions.setAudioOutputInProgress(true));
		try
		{
			const device = this._audioOutputDevices[deviceId];

			if (!device)
				throw new Error('Selected audio output device no longer available');

			store.dispatch(settingsActions.setSelectedAudioOutputDevice(deviceId));

			await this._updateAudioOutputDevices();
		}
		catch (error)
		{
			logger.error('changeAudioOutputDevice() [error:"%o"]', error);
		}

		store.dispatch(
			meActions.setAudioOutputInProgress(false));
	}

	async updateMic({
		start = false,
		restart = false || this._device.flag !== 'firefox',
		newDeviceId = null
	} = {})
	{
		logger.debug(
			'updateMic() [start:"%s", restart:"%s", newDeviceId:"%s"]',
			start,
			restart,
			newDeviceId
		);

		let track;

		try
		{
			if (!this._mediasoupDevice.canProduce('audio'))
				throw new Error('cannot produce audio');

			if (newDeviceId && !restart)
				throw new Error('changing device requires restart');

			if (newDeviceId)
				store.dispatch(settingsActions.setSelectedAudioDevice(newDeviceId));

			store.dispatch(meActions.setAudioInProgress(true));

			const deviceId = await this._getAudioDeviceId();
			const device = this._audioDevices[deviceId];

			if (!device)
				throw new Error('no audio devices');

			const {
				autoGainControl,
				echoCancellation,
				noiseSuppression
			} = store.getState().settings;

			const {
				sampleRate = 96000,
				channelCount = 1,
				volume = 1.0,
				sampleSize = 16,
				opusStereo = false,
				opusDtx = true,
				opusFec = true,
				opusPtime = 20,
				opusMaxPlaybackRate = 96000
			} = window.config.centralAudioOptions;


			if (
				(restart && this._micProducer) ||
				start
			)
			{
				this.disconnectLocalHark();

				if (this._micProducer)
					await this.disableMic();

				const stream = await navigator.mediaDevices.getUserMedia(
					{
						audio : {
							deviceId : { ideal: deviceId },
							sampleRate,
							channelCount,
							volume,
							autoGainControl,
							echoCancellation,
							noiseSuppression,
							sampleSize
						}
					}
				);

				([ track ] = stream.getAudioTracks());

				const { deviceId: trackDeviceId } = track.getSettings();

				store.dispatch(settingsActions.setSelectedAudioDevice(trackDeviceId));

				this._micProducer = await this._sendTransport.produce(
					{
						track,
						codecOptions :
						{
							opusStereo          : opusStereo,
							opusDtx             : opusDtx,
							opusFec             : opusFec,
							opusPtime           : opusPtime,
							opusMaxPlaybackRate	: opusMaxPlaybackRate
						},
						appData :
						{ source: 'mic' }
					});

				store.dispatch(producerActions.addProducer(
					{
						id            : this._micProducer.id,
						source        : 'mic',
						paused        : this._micProducer.paused,
						track         : this._micProducer.track,
						rtpParameters : this._micProducer.rtpParameters,
						codec         : this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
					}));

				this._micProducer.on('transportclose', () =>
				{
					this._micProducer = null;
				});

				this._micProducer.on('trackended', () =>
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'devices.microphoneDisconnected',
								defaultMessage : 'Microphone disconnected'
							})
						}));

					this.disableMic();
				});

				this._micProducer.volume = 0;

				this.connectLocalHark(track);
			}
			else if (this._micProducer)
			{
				({ track } = this._micProducer);

				await track.applyConstraints(
					{
						sampleRate,
						channelCount,
						volume,
						autoGainControl,
						echoCancellation,
						noiseSuppression,
						sampleSize
					}
				);

				if (this._harkStream != null)
				{
					const [ harkTrack ] = this._harkStream.getAudioTracks();

					harkTrack && await harkTrack.applyConstraints(
						{
							sampleRate,
							channelCount,
							volume,
							autoGainControl,
							echoCancellation,
							noiseSuppression,
							sampleSize
						}
					);
				}
			}
		}
		catch (error)
		{
			logger.error('updateMic() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.microphoneError',
						defaultMessage : 'An error occurred while accessing your microphone'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(meActions.setAudioInProgress(false));
	}

	async updateWebcam({
		start = false,
		restart = false,
		newDeviceId = null,
		newResolution = null,
		newFrameRate = null,
		isScreenShare = false
	} = {})
	{
		logger.debug(
			'updateWebcam() [start:"%s", restart:"%s", newDeviceId:"%s", newResolution:"%s", newFrameRate:"%s"]',
			start,
			restart,
			newDeviceId,
			newResolution,
			newFrameRate
		);

		let track;
		try
		{
			if (!this._mediasoupDevice.canProduce('video'))
				throw new Error('cannot produce video');

			if (newDeviceId && !restart)
				throw new Error('changing device requires restart');

			if (newDeviceId)
				store.dispatch(settingsActions.setSelectedWebcamDevice(newDeviceId));

			if (newResolution)
				store.dispatch(settingsActions.setVideoResolution(newResolution));

			if (newFrameRate)
				store.dispatch(settingsActions.setVideoFrameRate(newFrameRate));
			
			if (store.getState().settings.virtualBackground)
			{
				restart = true;
			}

			store.dispatch(meActions.setWebcamInProgress(true));

			const {
				resolution,
				frameRate
			} = store.getState().settings;

			if (
				(restart && this._webcamProducer) ||
				start
			)
			{
				if (this._webcamProducer)
					await this.disableWebcam();

				
				this._webcamProducer = await this._addProducer('webcam');

				store.dispatch(producerActions.addProducer(
					{
						id            : this._webcamProducer.id,
						source        : 'webcam',
						paused        : this._webcamProducer.paused,
						track         : this._webcamProducer.track,
						rtpParameters : this._webcamProducer.rtpParameters,
						codec         : this._webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
					}));

				this._webcamProducer.on('transportclose', () =>
				{
					this._webcamProducer = null;
					this._removeMergerStream('localVideo');
				});

				this._webcamProducer.on('trackended', () =>
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'devices.cameraDisconnected',
								defaultMessage : 'Camera disconnected'
							})
						}));

					this.disableWebcam();
				});
			}
			else if (this._webcamProducer)
			{
				({ track } = this._webcamProducer);

				await track.applyConstraints(
					{
						...VIDEO_CONSTRAINS[resolution],
						frameRate
					}
				);

				// Also change resolution of extra video producers
				for (const producer of this._extraVideoProducers.values())
				{
					({ track } = producer);

					await track.applyConstraints(
						{
							...VIDEO_CONSTRAINS[resolution],
							frameRate
						}
					);
				}
			}

			if (this._webcamProducer && this._webcamProducer !== null && store.getState().settings.virtualBackground && streamBlurEffect)
			{
				await streamBlurEffect.startEffect(this._webcamProducer.track, 'canvasVideo', store.getState().settings.virtualBackground);
			}
			
			if (this._webcamProducer && this._webcamProducer !== null)
			{
				// if (this._screenSharing && this._screenSharing.isScreenShareAvailable())
				// {
				// 	this._screenSharing._addStream();
				// }
				this._addVideoMergerStream({id: 'localVideo', track: this._webcamProducer.track});
			}
		}
		catch (error)
		{
			logger.error('updateWebcam() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.cameraError',
						defaultMessage : 'An error occurred while accessing your camera'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(
			meActions.setWebcamInProgress(false));
	}

	// setSelectedPeer(peerId)
	// {
	// 	logger.debug('setSelectedPeer() [peerId:"%s"]', peerId);
	// 	// const { sharescreenId } = store.getState().room;

	// 	// if (sharescreenId && sharescreenId !== null)
	// 	//	return;

	// 	//this._spotlights.setPeerSpotlight(peerId);

	// 	store.dispatch(
	// 		roomActions.setSelectedPeer(peerId));
	// }

	async promoteAllLobbyPeers()
	{
		logger.debug('promoteAllLobbyPeers()');

		store.dispatch(
			roomActions.setLobbyPeersPromotionInProgress(true));

		try
		{
			await this.sendRequest('promoteAllPeers');
		}
		catch (error)
		{
			logger.error('promoteAllLobbyPeers() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setLobbyPeersPromotionInProgress(false));
	}

	async promoteLobbyPeer(peerId)
	{
		logger.debug('promoteLobbyPeer() [peerId:"%s"]', peerId);

		store.dispatch(
			lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, true));

		try
		{
			await this.sendRequest('promotePeer', { peerId });
		}
		catch (error)
		{
			logger.error('promoteLobbyPeer() [error:"%o"]', error);
		}

		store.dispatch(
			lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, false));
	}

	async clearChat()
	{
		logger.debug('clearChat()');

		store.dispatch(
			roomActions.setClearChatInProgress(true));

		try
		{
			await this.sendRequest('moderator:clearChat');

			store.dispatch(chatActions.clearChat());
		}
		catch (error)
		{
			logger.error('clearChat() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setClearChatInProgress(false));
	}

	async clearFileSharing()
	{
		logger.debug('clearFileSharing()');

		store.dispatch(
			roomActions.setClearFileSharingInProgress(true));

		try
		{
			await this.sendRequest('moderator:clearFileSharing');

			store.dispatch(fileActions.clearFiles());
		}
		catch (error)
		{
			logger.error('clearFileSharing() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setClearFileSharingInProgress(false));
	}

	async kickPeer(peerId)
	{
		logger.debug('kickPeer() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setPeerKickInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:kickPeer', { peerId });
		}
		catch (error)
		{
			logger.error('kickPeer() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerKickInProgress(peerId, false));
	}

	async mutePeer(peerId)
	{
		logger.debug('mutePeer() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setMutePeerInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:mute', { peerId });
		}
		catch (error)
		{
			logger.error('mutePeer() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setMutePeerInProgress(peerId, false));
	}

	async stopPeerVideo(peerId)
	{
		logger.debug('stopPeerVideo() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setStopPeerVideoInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:stopVideo', { peerId });
		}
		catch (error)
		{
			logger.error('stopPeerVideo() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setStopPeerVideoInProgress(peerId, false));
	}

	async stopPeerScreenSharing(peerId)
	{
		logger.debug('stopPeerScreenSharing() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setStopPeerScreenSharingInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:stopScreenSharing', { peerId });
		}
		catch (error)
		{
			logger.error('stopPeerScreenSharing() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setStopPeerScreenSharingInProgress(peerId, false));
	}

	async muteAllPeers()
	{
		logger.debug('muteAllPeers()');

		store.dispatch(
			roomActions.setMuteAllInProgress(true));

		try
		{
			await this.sendRequest('moderator:muteAll');
		}
		catch (error)
		{
			logger.error('muteAllPeers() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setMuteAllInProgress(false));
	}

	async stopAllPeerVideo()
	{
		logger.debug('stopAllPeerVideo()');

		store.dispatch(
			roomActions.setStopAllVideoInProgress(true));

		try
		{
			await this.sendRequest('moderator:stopAllVideo');
		}
		catch (error)
		{
			logger.error('stopAllPeerVideo() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setStopAllVideoInProgress(false));
	}

	async stopAllPeerScreenSharing()
	{
		logger.debug('stopAllPeerScreenSharing()');

		store.dispatch(
			roomActions.setStopAllScreenSharingInProgress(true));

		try
		{
			await this.sendRequest('moderator:stopAllScreenSharing');
		}
		catch (error)
		{
			logger.error('stopAllPeerScreenSharing() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setStopAllScreenSharingInProgress(false));
	}

	async closeMeeting()
	{
		logger.debug('closeMeeting()');

		store.dispatch(
			roomActions.setCloseMeetingInProgress(true));

		try
		{
			await this.sendRequest('moderator:closeMeeting');
			if (clientAuthen)
			{
				await actionHostMeeting({uuid: this._roomId}, END_ACTION);
			}
		}
		catch (error)
		{
			logger.error('closeMeeting() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setCloseMeetingInProgress(false));

	}

	// type: mic/webcam/screen
	// mute: true/false
	async modifyPeerConsumer(peerId, type, mute)
	{
		logger.debug(
			'modifyPeerConsumer() [peerId:"%s", type:"%s"]',
			peerId,
			type
		);

		if (type === 'mic')
			{
				store.dispatch(
				peerActions.setPeerAudioInProgress(peerId, true));
			}
		else if (type === 'webcam')
			store.dispatch(
				peerActions.setPeerVideoInProgress(peerId, true));
		else if (type === 'screen')
			store.dispatch(
				peerActions.setPeerScreenInProgress(peerId, true));

		try
		{
			for (const consumer of this._consumers.values())
			{
				if (consumer.appData.peerId === peerId && consumer.appData.source === type)
				{
					if (mute)
						await this._pauseConsumer(consumer);
					else
						await this._resumeConsumer(consumer);
				}
			}
		}
		catch (error)
		{
			logger.error('modifyPeerConsumer() [error:"%o"]', error);
		}

		if (type === 'mic')
			store.dispatch(
				peerActions.setPeerAudioInProgress(peerId, false));
		else if (type === 'webcam')
			store.dispatch(
				peerActions.setPeerVideoInProgress(peerId, false));
		else if (type === 'screen')
			store.dispatch(
				peerActions.setPeerScreenInProgress(peerId, false));
	}

	async _pauseConsumer(consumer)
	{
		logger.debug('_pauseConsumer() [consumer:"%o"]', consumer);

		if (consumer.paused || consumer.closed)
			return;

		try
		{
			await this.sendRequest('pauseConsumer', { consumerId: consumer.id });

			consumer.pause();

			store.dispatch(
				consumerActions.setConsumerPaused(consumer.id, 'local'));
			if (consumer.track.kind === 'video')
			{
				await this.calculateSize();
				this._removeMergerStream(consumer.id);
			}
		}
		catch (error)
		{
			logger.error('_pauseConsumer() [error:"%o"]', error);
		}
	}

	async _resumeConsumer(consumer)
	{
		logger.debug('_resumeConsumer() [consumer:"%o"]', consumer);

		if (!consumer.paused || consumer.closed)
			return;

		try
		{
			await this.sendRequest('resumeConsumer', { consumerId: consumer.id });
			consumer.resume();

			store.dispatch(
				consumerActions.setConsumerResumed(consumer.id, 'local'));
			if (consumer.track.kind === 'video')
			{
				await this.calculateSize();
				const { displayName } = store.getState().peers[consumer.appData.peerId];
				this._addVideoMergerStream({id: consumer.id,
										track: consumer.track,
										isScreen: (consumer.appData.source === 'screen'),
										peerId: consumer.appData.peerId,
										name: displayName});
			}
		}
		catch (error)
		{
			logger.error('_resumeConsumer() [error:"%o"]', error);
		}
	}

	async lowerPeerHand(peerId)
	{
		logger.debug('lowerPeerHand() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setPeerRaisedHandInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:lowerHand', { peerId });
		}
		catch (error)
		{
			logger.error('lowerPeerHand() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerRaisedHandInProgress(peerId, false));
	}

	async setRaisedHand(raisedHand)
	{
		logger.debug('setRaisedHand: ', raisedHand);

		store.dispatch(
			meActions.setRaisedHandInProgress(true));

		try
		{
			await this.sendRequest('raisedHand', { raisedHand });

			store.dispatch(
				meActions.setRaisedHand(raisedHand));
		}
		catch (error)
		{
			logger.error('setRaisedHand() [error:"%o"]', error);

			// We need to refresh the component for it to render changed state
			store.dispatch(meActions.setRaisedHand(!raisedHand));
		}

		store.dispatch(
			meActions.setRaisedHandInProgress(false));
	}

	async setMaxSendingSpatialLayer(spatialLayer)
	{
		logger.debug('setMaxSendingSpatialLayer() [spatialLayer:"%s"]', spatialLayer);

		try
		{
			if (this._webcamProducer)
				await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
			if (this._screenSharingProducer)
				await this._screenSharingProducer.setMaxSpatialLayer(spatialLayer);
		}
		catch (error)
		{
			logger.error('setMaxSendingSpatialLayer() [error:"%o"]', error);
		}
	}

	async setConsumerPreferredLayers(consumer, consumerId, newSpatialLayer, newTemporalLayer)
	{
		if (consumer.currentSpatialLayer && consumer.currentSpatialLayer === newSpatialLayer)
			return;
		
		logger.debug(
			'setConsumerPreferredLayers() [consumerId:"%s", spatialLayer:"%s", temporalLayer:"%s"]',
			consumerId, newSpatialLayer, newTemporalLayer);

		try
		{
			await this.sendRequest(
				'setConsumerPreferedLayers', { consumerId, spatialLayer: newSpatialLayer, temporalLayer: 2 });

			store.dispatch(consumerActions.setConsumerPreferredLayers(
				consumerId, newSpatialLayer, 2 ));

			// consumer.currentSpatialLayer = newSpatialLayer;
		}
		catch (error)
		{
			logger.error('setConsumerPreferredLayers() [error:"%o"]', error);
		}
	}

	async setConsumerPriority(consumerId, priority)
	{
		logger.debug(
			'setConsumerPriority() [consumerId:"%s", priority:%d]',
			consumerId, priority);

		try
		{
			await this.sendRequest('setConsumerPriority', { consumerId, priority });

			store.dispatch(consumerActions.setConsumerPriority(consumerId, priority));
		}
		catch (error)
		{
			logger.error('setConsumerPriority() [error:"%o"]', error);
		}
	}

	async requestConsumerKeyFrame(consumerId)
	{
		logger.debug('requestConsumerKeyFrame() [consumerId:"%s"]', consumerId);

		try
		{
			await this.sendRequest('requestConsumerKeyFrame', { consumerId });
		}
		catch (error)
		{
			logger.error('requestConsumerKeyFrame() [error:"%o"]', error);
		}
	}

	async _loadDynamicImports()
	{
		({ default: createTorrent } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "createtorrent" */
			'create-torrent'
		));

		({ default: WebTorrent } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "webtorrent" */
			'webtorrent'
		));

		({ default: saveAs } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "file-saver" */
			'file-saver'
		));

		({ default: ScreenShare } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "screensharing" */
			'./ScreenShare'
		));

		({ default: Spotlights } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "spotlights" */
			'./Spotlights'
		));

		mediasoupClient = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "mediasoup" */
			'mediasoup-client'
		);

		({ default: io } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "socket.io" */
			'socket.io-client'
		));
	}

	setJoinWithVideo(flag)
	{
		this._joinWithVideo = flag;
	}

	setJoinWithAudio(flag)
	{
		this._joinWithAudio = flag;
	}

	async join({ roomId, joinVideo, muteMic , dataInit, stream})
	{
		await this._loadDynamicImports();

		if (!window.streamBlurEffect && window.config.blurEffect)
		{
			streamBlurEffect = await createBlurEffect();
		}
		else
		{
			streamBlurEffect = window.streamBlurEffect;
		}
		if (streamBlurEffect && streamBlurEffect.isStartEffect())
		{
			streamBlurEffect.stopEffect();
		}

		this._joinWithVideo = joinVideo;
		this._joinWithAudio = !muteMic;

		this._roomId = roomId;

		let role = NORMAL;
		if (dataInit && dataInit.userType)
			role = dataInit.userType;

		if (dataInit)
			this.dataInit = dataInit;

		logger.debug('join [room:"%s", role: "%s"]', roomId, role);
		if(roomId){
			store.dispatch(roomActions.setRoomName(roomId));
		}
		
		if (dataInit.displayName)
		{
			store.dispatch(settingsActions.setDisplayName(dataInit.displayName));
		}

		this._signalingUrl = getSignalingUrl(this._peerId, roomId, role);

		this._screenSharing = ScreenShare.create(this._device);

		this._signalingSocket = io(this._signalingUrl);

		this._spotlights = new Spotlights(this._maxSpotlights, this._signalingSocket);

		store.dispatch(roomActions.setRoomState('connecting'));

		this._signalingSocket.on('connect', () =>
		{
			logger.debug('signaling Peer "connect" event');
		});
		this._signalingSocket.on('disconnect', (reason) =>
		{
			logger.warn('signaling Peer "disconnect" event [reason:"%s"]', reason);

			if (this._closed)
				return;

			if (reason === 'io server disconnect')
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'socket.disconnected',
							defaultMessage : 'You are disconnected'
						})
					}));
				
				this.close();
			}

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.reconnecting',
						defaultMessage : 'You are disconnected, attempting to reconnect'
					})
				}));

			if (this._screenSharingProducer)
			{
				this._screenSharingProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._screenSharingProducer.id));

				this._screenSharingProducer = null;
			}

			if (this._webcamProducer)
			{
				this._webcamProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._webcamProducer.id));

				this._webcamProducer = null;
			}

			if (this._micProducer)
			{
				this._micProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._micProducer.id));

				this._micProducer = null;
			}

			if (this._sendTransport)
			{
				this._sendTransport.close();

				this._sendTransport = null;
			}

			if (this._recvTransport)
			{
				this._recvTransport.close();

				this._recvTransport = null;
			}

			this._spotlights.clearSpotlights();

			store.dispatch(peerActions.clearPeers());
			store.dispatch(consumerActions.clearConsumers());
			store.dispatch(roomActions.clearSpotlights());
			store.dispatch(roomActions.setRoomState('connecting'));

			if (this._reconect && reason === 'io server disconnect')
			{
				const joinVideo = (this._webcamProducer !== null);
				const muteMic = (this._micProducer === null);
				this.join({roomId: this._roomId, joinVideo, muteMic, dataInit: this.dataInit});
				this._reconect = false;
			}
		});

		this._signalingSocket.on('reconnect_failed', () =>
		{
			logger.warn('signaling Peer "reconnect_failed" event');

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.disconnected',
						defaultMessage : 'You are disconnected'
					})
				}));

			this.close();
		});

		this._signalingSocket.on('reconnect', (attemptNumber) =>
		{
			logger.debug('signaling Peer "reconnect" event [attempts:"%s"]', attemptNumber);

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.reconnected',
						defaultMessage : 'You are reconnected'
					})
				}));

			store.dispatch(roomActions.setRoomState('connected'));
		});

		this._signalingSocket.on('request', async (request, cb) =>
		{
			logger.debug(
				'socket "request" event [method:"%s", data:"%o"]',
				request.method, request.data);

			switch (request.method)
			{
				case 'newConsumer':
				{
					const {
						peerId,
						producerId,
						id,
						kind,
						rtpParameters,
						type,
						appData,
						producerPaused
					} = request.data;

					const consumer = await this._recvTransport.consume(
						{
							id,
							producerId,
							kind,
							rtpParameters,
							appData : { ...appData, peerId } // Trick.
						});

					// Store in the map.
					this._consumers.set(consumer.id, consumer);

					consumer.on('transportclose', () =>
					{
						this._consumers.delete(consumer.id);
						if (kind === 'audio')
						{
							this._peerAudioMap.delete(peerId);
						}						
						else
						{
							this.calculateSize();
						}
					});

					const { spatialLayers, temporalLayers } =
						mediasoupClient.parseScalabilityMode(
							consumer.rtpParameters.encodings[0].scalabilityMode);

					store.dispatch(consumerActions.addConsumer(
						{
							id                     : consumer.id,
							peerId                 : peerId,
							kind                   : kind,
							type                   : type,
							locallyPaused          : false,
							remotelyPaused         : producerPaused,
							rtpParameters          : consumer.rtpParameters,
							source                 : consumer.appData.source,
							spatialLayers          : spatialLayers,
							temporalLayers         : temporalLayers,
							preferredSpatialLayer  : spatialLayers - 1,
							preferredTemporalLayer : temporalLayers - 1,
							priority               : 1,
							codec                  : consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
							track                  : consumer.track
						},
						peerId));

					// We are ready. Answer the request so the server will
					// resume this Consumer (which was paused for now).
					cb(null);

					if (kind === 'audio')
					{
						consumer.volume = 0;

						const stream = new MediaStream();

						stream.addTrack(consumer.track);

						if (!stream.getAudioTracks()[0])
							throw new Error('request.newConsumer | given stream has no audio track');

						consumer.hark = hark(stream, { play: false });

						consumer.hark.on('volume_change', (volume) =>
						{
							volume = Math.round(volume);

							if (consumer && volume !== consumer.volume)
							{
								consumer.volume = volume;

								store.dispatch(peerVolumeActions.setPeerVolume(peerId, volume));
							}
						});
						this._peerAudioMap.set(peerId, consumer.id);
					}
					else
					{
						const { displayName } = store.getState().peers[peerId];

						this._addVideoMergerStream({id: consumer.id,
							track: consumer.track,
							isScreen: (consumer._appData.source === 'screen'),
							peerId: peerId,
							name: displayName});

						if(consumer._appData.source === 'screen') {
							store.dispatch(roomActions.setRoomActiveSpeaker(peerId));
							this._spotlights.handleActiveSpeaker(consumer.appData.peerId);
							this.changeDisplayMode(LayoutMode.FILMSTRIP, false);
							// this.setSelectedPeer(consumer.appData.peerId);							
							store.dispatch(roomActions.setSharescreenVideo(consumer.id));
							store.dispatch(roomActions.setCanSharescreenVideo(false));
						}
						await this.calculateSize();
					}

					break;
				}

				default:
				{
					logger.error('unknown request.method "%s"', request.method);

					cb(500, `unknown request.method "${request.method}"`);
				}
			}
		});

		this._signalingSocket.on('notification', async (notification) =>
		{
			logger.debug(
				'socket "notification" event [method:"%s", data:"%o"]',
				notification.method, notification.data);

			try
			{
				switch (notification.method)
				{

					case 'enteredLobby':
					{
						if (stream && stream !== null)
						{
							stream.getVideoTracks()[0].stop();
						}
						store.dispatch(roomActions.setInLobby(true));

						const { displayName } = store.getState().settings;
						const { picture } = store.getState().me;

						await this.sendRequest('changeDisplayName', { displayName });
						await this.sendRequest('changePicture', { picture });
						break;
					}

					case 'signInRequired':
					{
						store.dispatch(roomActions.setSignInRequired(true));

						break;
					}

					case 'overRoomLimit':
					{
						store.dispatch(roomActions.setOverRoomLimit(true));

						break;
					}

					case 'roomReady':
					{
						//recorderServer
						const { turnServers} = notification.data;
						//this._recorderServer = recorderServer;

						this._recorderServer = false;
						if (this._recorderServer)
						{
							this.getCanvas();
						}
						try {
							this._turnServers = genTurn(turnServers, this._device.flag);
							store.dispatch(roomActions.toggleJoined());
							store.dispatch(roomActions.setInLobby(false));
							if (stream && stream !== null)
							{
								stream.getVideoTracks()[0].stop();
							}
						} catch (error) {}
						
						await this._joinRoom();

						break;
					}

					case 'roomBack':
					{
						store.dispatch(roomActions.toggleJoined());
						store.dispatch(roomActions.setInLobby(false));
						await this._joinRoom();
						break;
					}

					case 'lockRoom':
					{
						store.dispatch(
							roomActions.setRoomLocked());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.locked',
									defaultMessage : 'Room is now locked'
								})
							}));

						break;
					}

					case 'unlockRoom':
					{
						store.dispatch(
							roomActions.setRoomUnLocked());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.unlocked',
									defaultMessage : 'Room is now unlocked'
								})
							}));

						break;
					}

					case 'parkedPeer':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.addLobbyPeer(peerId));
						store.dispatch(
							roomActions.setToolbarsVisible(true));

						this._soundNotification();

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.newLobbyPeer',
									defaultMessage : 'New participant entered the lobby'
								})
							}));

						break;
					}

					case 'parkedPeers':
					{
						const { lobbyPeers } = notification.data;

						if (lobbyPeers.length > 0)
						{
							lobbyPeers.forEach((peer) =>
							{
								store.dispatch(
									lobbyPeerActions.addLobbyPeer(peer.id));

								store.dispatch(
									lobbyPeerActions.setLobbyPeerDisplayName(
										peer.displayName,
										peer.id
									)
								);

								store.dispatch(
									lobbyPeerActions.setLobbyPeerPicture(
										peer.picture,
										peer.id
									)
								);
							});

							store.dispatch(
								roomActions.setToolbarsVisible(true));

							this._soundNotification();

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.newLobbyPeer',
										defaultMessage : 'New participant entered the lobby'
									})
								}));
						}

						break;
					}

					case 'lobby:peerClosed':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.removeLobbyPeer(peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.lobbyPeerLeft',
									defaultMessage : 'Participant in lobby left'
								})
							}));

						break;
					}

					case 'lobby:promotedPeer':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.removeLobbyPeer(peerId));

						break;
					}

					case 'lobby:changeDisplayName':
					{
						const { peerId, displayName } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerDisplayName(displayName, peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.lobbyPeerChangedDisplayName',
									defaultMessage : 'Participant in lobby changed name to {displayName}'
								}, {
									displayName
								})
							}));

						break;
					}

					case 'lobby:changePicture':
					{
						const { peerId, picture } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerPicture(picture, peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.lobbyPeerChangedPicture',
									defaultMessage : 'Participant in lobby changed picture'
								})
							}));

						break;
					}

					case 'setAccessCode':
					{
						const { accessCode } = notification.data;

						store.dispatch(
							roomActions.setAccessCode(accessCode));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.setAccessCode',
									defaultMessage : 'Access code for room updated'
								})
							}));

						break;
					}

					case 'setJoinByAccessCode':
					{
						const { joinByAccessCode } = notification.data;

						store.dispatch(
							roomActions.setJoinByAccessCode(joinByAccessCode));

						if (joinByAccessCode)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.accessCodeOn',
										defaultMessage : 'Access code for room is now activated'
									})
								}));
						}
						else
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.accessCodeOff',
										defaultMessage : 'Access code for room is now deactivated'
									})
								}));
						}

						break;
					}

					case 'activeSpeaker':
					{
						const { peerId } = notification.data;

						const { sharescreenId, activeSpeakerId } = store.getState().room;

						if (!sharescreenId || sharescreenId === null)
						{							
							if (peerId !== activeSpeakerId && this._lastActiveSpeaker === null)
							{
								store.dispatch(roomActions.setRoomActiveSpeaker(peerId));
								if (peerId && peerId !== this._peerId)
									this._spotlights.handleActiveSpeaker(peerId);
							}
						}					
						if (peerId && peerId !== null)
							this.handleActiveSpeaker(peerId);
						
						this._lastActiveSpeaker = peerId;
						break;
					}

					case 'changeDisplayName':
					{
						const { peerId, displayName, oldDisplayName } = notification.data;

						store.dispatch(
							peerActions.setPeerDisplayName(displayName, peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.peerChangedDisplayName',
									defaultMessage : '{oldDisplayName} is now {displayName}'
								}, {
									oldDisplayName,
									displayName
								})
							}));

						break;
					}

					case 'changePicture':
					{
						const { peerId, picture } = notification.data;

						store.dispatch(peerActions.setPeerPicture(peerId, picture));

						break;
					}

					case 'raisedHand':
					{
						const {
							peerId,
							raisedHand,
							raisedHandTimestamp
						} = notification.data;

						store.dispatch(
							peerActions.setPeerRaisedHand(
								peerId,
								raisedHand,
								raisedHandTimestamp
							)
						);

						const { displayName } = store.getState().peers[peerId];

						let text;

						if (raisedHand)
						{
							text = intl.formatMessage({
								id             : 'room.raisedHand',
								defaultMessage : '{displayName} raised their hand'
							}, {
								displayName
							});
						}
						else
						{
							text = intl.formatMessage({
								id             : 'room.loweredHand',
								defaultMessage : '{displayName} put their hand down'
							}, {
								displayName
							});
						}

						if (displayName)
						{
							store.dispatch(requestActions.notify(
								{
									text
								}));
						}

						this._soundNotification();

						break;
					}

					case 'chatMessage':
					{
						const { peerId, chatMessage } = notification.data;

						store.dispatch(
							chatActions.addResponseMessage({ ...chatMessage, peerId }));

						if (
							!store.getState().toolarea.toolAreaOpen ||
							(store.getState().toolarea.toolAreaOpen &&
							store.getState().toolarea.currentToolTab !== 'chat')
						) // Make sound
						{
							store.dispatch(
								roomActions.setToolbarsVisible(true));
							this._soundNotification();
						}

						break;
					}

					case 'moderator:clearChat':
					{
						store.dispatch(chatActions.clearChat());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.clearChat',
									defaultMessage : 'Moderator cleared the chat'
								})
							}));

						break;
					}

					case 'sendFile':
					{
						const { peerId, magnetUri } = notification.data;

						store.dispatch(fileActions.addFile(peerId, magnetUri));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.newFile',
									defaultMessage : 'New file available'
								})
							}));

						if (
							!store.getState().toolarea.toolAreaOpen ||
							(store.getState().toolarea.toolAreaOpen &&
							store.getState().toolarea.currentToolTab !== 'files')
						) // Make sound
						{
							store.dispatch(
								roomActions.setToolbarsVisible(true));
							this._soundNotification();
						}

						break;
					}

					case 'moderator:clearFileSharing':
					{
						store.dispatch(fileActions.clearFiles());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.clearFiles',
									defaultMessage : 'Moderator cleared the files'
								})
							}));

						break;
					}

					case 'producerScore':
					{
						const { producerId, score } = notification.data;

						store.dispatch(
							producerActions.setProducerScore(producerId, score));

						break;
					}

					case 'newPeer':
					{
						const { id, displayName, picture, roles } = notification.data;
						store.dispatch(
						peerActions.addPeer({ id, displayName, picture, roles, consumers: [] }));

						this._soundNotification();
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.newPeer',
									defaultMessage : '{displayName} joined the room'
								}, {
									displayName
								})
							}));				
						break;
					}

					case 'peerClosed':
					{
						const { peerId } = notification.data;
						this._peerAudioMap.delete(peerId);

						store.dispatch(
							peerActions.removePeer(peerId));

						break;
					}

					case 'consumerClosed':
					{
						const { consumerId } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;
						
						// Enable sharescreen when no one sharescreen
						const { sharescreenId } = store.getState().room;
						if(consumer.id === sharescreenId || consumer.appData.source === 'screen') {
							store.dispatch(roomActions.setSharescreenVideo(null))
							store.dispatch(roomActions.setCanSharescreenVideo(true))
							this.changeDisplayMode(LayoutMode.DEMOCRATIC, false);
						}
						consumer.close();

						if (consumer.hark != null)
							consumer.hark.stop();

						this._consumers.delete(consumerId);

						if (consumer.track.kind === 'video')
						{
							await this.calculateSize();
						}
						const { peerId } = consumer.appData;

						store.dispatch(
							consumerActions.removeConsumer(consumerId, peerId));

						this._removeMergerStream(consumerId);
						break;
					}

					case 'consumerPaused':
					{
						const { consumerId } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;

						store.dispatch(
							consumerActions.setConsumerPaused(consumerId, 'remote'));

						if (consumer.track.kind === 'video')
						{
							this.calculateSize();
						}
						break;
					}

					case 'consumerResumed':
					{
						const { consumerId } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;

						store.dispatch(
							consumerActions.setConsumerResumed(consumerId, 'remote'));
						if (consumer.track.kind === 'video')
						{
							this.calculateSize();
						}				

						break;
					}

					case 'consumerLayersChanged':
					{
						const { consumerId, spatialLayer, temporalLayer } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;

						store.dispatch(consumerActions.setConsumerCurrentLayers(
							consumerId, spatialLayer, temporalLayer));

						break;
					}

					case 'consumerScore':
					{
						const { consumerId, score } = notification.data;

						store.dispatch(
							consumerActions.setConsumerScore(consumerId, score));

						break;
					}

					case 'moderator:mute':
					{
						if (this._micProducer && !this._micProducer.paused)
						{
							this.muteMic();

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'moderator.muteAudio',
										defaultMessage : 'Moderator muted your audio'
									})
								}));
						}

						break;
					}

					case 'moderator:stopVideo':
					{
						this.disableWebcam();

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.muteVideo',
									defaultMessage : 'Moderator stopped your video'
								})
							}));

						break;
					}

					case 'moderator:stopScreenSharing':
					{
						this.disableScreenSharing();

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.stopScreenSharing',
									defaultMessage : 'Moderator stopped your screen sharing'
								})
							}));

						break;
					}

					case 'moderator:kick':
					{
						// Need some feedback
						this.close();

						break;
					}

					case 'moderator:lowerHand':
					{
						this.setRaisedHand(false);

						break;
					}

					case 'gotRole':
					{
						const { peerId, role } = notification.data;

						if (peerId === this._peerId)
						{
							store.dispatch(meActions.addRole(role));

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'roles.gotRole',
										defaultMessage : 'You got the role: {role}'
									}, {
										role
									})
								}));
						}
						else
							store.dispatch(peerActions.addPeerRole(peerId, role));

						break;
					}

					case 'lostRole':
					{
						const { peerId, role } = notification.data;

						if (peerId === this._peerId)
						{
							store.dispatch(meActions.removeRole(role));

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'roles.lostRole',
										defaultMessage : 'You lost the role: {role}'
									}, {
										role
									})
								}));
						}
						else
							store.dispatch(peerActions.removePeerRole(peerId, role));

						break;
					}

					case 'whiteBoardData':
					{
						const { object, slideIndex, slideChanged, actionSyncObject, canvasProperty } = notification.data;

						const { whiteboardOpen } = store.getState().whiteboard;

						if (whiteboardOpen) {
							store.dispatch(whiteboardActions.addDataWhiteboard(object, slideChanged, slideIndex, actionSyncObject, canvasProperty));
							if (actionSyncObject === _actionSyncObject.ADD_NEW_PAGE) {

								store.dispatch(whiteboardActions.nextSlide(true));
	
							} else if (actionSyncObject === _actionSyncObject.IMPORT_JSON_DATA) {
								if (whiteboardOpen) {
									store.dispatch(
										whiteboardActions.closeWhiteboard());
									store.dispatch(
										whiteboardActions.openWhiteboard());
								}
							}
						}
						break
					}

					case 'setPermisionWhiteboard':
						{
							const {peerId, role} = notification.data;

							const {id} = store.getState().me;

							if (peerId === id) {
								store.dispatch(
									whiteboardActions.grantPermissionWhiteboard({
										peerId: peerId,
										role: role
									}));

								const {
									whiteboardOpen
								} = store.getState().whiteboard;

								if (whiteboardOpen) {
									store.dispatch(
										whiteboardActions.closeWhiteboard());
									store.dispatch(
										whiteboardActions.openWhiteboard());
								}
							}
							break
						}

					default:
					{
						logger.error(
							'unknown notification.method "%s"', notification.method);
					}
				}
			}
			catch (error)
			{
				logger.error('error on socket "notification" event [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'socket.requestError',
							defaultMessage : 'Error on server request'
						})
					}));
			}

		});
	}

	async _joinRoom()
	{
		logger.debug('_joinRoom()');

		const { displayName } = store.getState().settings;
		const { picture } = store.getState().me;	
		try
		{
			this._torrentSupport = WebTorrent.WEBRTC_SUPPORT;

			this._webTorrent = this._torrentSupport && new WebTorrent({
				tracker : {
					rtcConfig : {
						iceServers : this._turnServers
					}
				}
			});

			this._webTorrent.on('error', (error) =>
			{
				logger.error('Filesharing [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.error',
							defaultMessage : 'There was a filesharing error'
						})
					}));
			});
			this._mediasoupDevice = new mediasoupClient.Device(
				{
					handlerName : this._handlerName
				});

			const routerRtpCapabilities =
				await this.sendRequest('getRouterRtpCapabilities');

			
			routerRtpCapabilities.headerExtensions = routerRtpCapabilities.headerExtensions
				.filter((ext) => ext.uri !== 'urn:3gpp:video-orientation');
			
			await this._mediasoupDevice.load({ routerRtpCapabilities });

			if (this._produce)
			{
				const transportInfo = await this.sendRequest(
					'createWebRtcTransport',
					{
						forceTcp  : this._forceTcp,
						producing : true,
						consuming : false
					});

				const {
					id,
					iceParameters,
					iceCandidates,
					dtlsParameters
				} = transportInfo;

				debugger
				this._sendTransport = this._mediasoupDevice.createSendTransport(
					{
						id,
						iceParameters,
						iceCandidates,
						dtlsParameters,
						iceServers             : this._turnServers,
						// TODO: Fix for issue #72
						iceTransportPolicy     : window.config.iceTransportPolicy?  window.config.iceTransportPolicy : (this._device.flag === 'firefox' && this._turnServers ? 'relay' : undefined),
						proprietaryConstraints : PC_PROPRIETARY_CONSTRAINTS
					});

				this._sendTransport.on(
					'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
					{
						this.sendRequest(
							'connectWebRtcTransport',
							{
								transportId : this._sendTransport.id,
								dtlsParameters
							})
							.then(callback)
							.catch(errback);
					});

				this._sendTransport.on(
					'produce', async ({ kind, rtpParameters, appData }, callback, errback) =>
					{
						try
						{
							// eslint-disable-next-line no-shadow
							const { id } = await this.sendRequest(
								'produce',
								{
									transportId : this._sendTransport.id,
									kind,
									rtpParameters,
									appData
								});

							callback({ id });
						}
						catch (error)
						{
							errback(error);
						}
					});
				if (this._recorderServer)
				{
					this._dummyProducer = this._addProducer('dummy');
				}
			}

			const transportInfo = await this.sendRequest(
				'createWebRtcTransport',
				{
					forceTcp  : this._forceTcp,
					producing : false,
					consuming : true
				});

			const {
				id,
				iceParameters,
				iceCandidates,
				dtlsParameters
			} = transportInfo;
			debugger
			this._recvTransport = this._mediasoupDevice.createRecvTransport(
				{
					id,
					iceParameters,
					iceCandidates,
					dtlsParameters,
					iceServers         : this._turnServers,
					// TODO: Fix for issue #72
					iceTransportPolicy : window.config.iceTransportPolicy?  window.config.iceTransportPolicy : (this._device.flag === 'firefox' && this._turnServers ? 'relay' : undefined)
				});

			this._recvTransport.on(
				'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
				{
					this.sendRequest(
						'connectWebRtcTransport',
						{
							transportId : this._recvTransport.id,
							dtlsParameters
						})
						.then(callback)
						.catch(errback);
				});

			// Set our media capabilities.
			store.dispatch(meActions.setMediaCapabilities(
				{
					canSendMic     : this._mediasoupDevice.canProduce('audio'),
					canSendWebcam  : this._mediasoupDevice.canProduce('video'),
					canShareScreen : this._mediasoupDevice.canProduce('video') &&
						this._screenSharing.isScreenShareAvailable(),
					canShareFiles : this._torrentSupport
				}));

			const {
				authenticated,
				roles,
				peers,
				tracker,
				roomPermissions,
				allowWhenRoleMissing,
				chatHistory,
				fileHistory,
				lastNHistory,
				locked,
				lobbyPeers,
				accessCode
			} = await this.sendRequest(
				'join',
				{
					displayName     : displayName,
					picture         : picture,
					rtpCapabilities : this._mediasoupDevice.rtpCapabilities
				});

			logger.debug(
				'_joinRoom() joined [authenticated:"%s", peers:"%o", roles:"%o"]',
				authenticated,
				peers,
				roles
			);

			tracker && (this._tracker = tracker);

			store.dispatch(meActions.loggedIn(authenticated));
			store.dispatch(roomActions.setRoomPermissions(roomPermissions));

			if (allowWhenRoleMissing)
				store.dispatch(roomActions.setAllowWhenRoleMissing(allowWhenRoleMissing));

			const myRoles = store.getState().me.roles;
			for (const role of roles)
			{
				if (!myRoles.includes(role))
				{
					store.dispatch(meActions.addRole(role));

					store.dispatch(requestActions.notify(
						{
							text : intl.formatMessage({
								id             : 'roles.gotRole',
								defaultMessage : 'You got the role: {role}'
							}, {
								role
							})
						}));
				}
			}
			for (const peer of peers)
			{
				store.dispatch(
					peerActions.addPeer({ ...peer, consumers: [] }));
			}
			this._spotlights.addPeers(peers);

			this._spotlights.on('spotlights-updated', (spotlights) =>
			{
				store.dispatch(roomActions.setSpotlights(spotlights));
				this.updateSpotlights(spotlights);
			});
			

			if (chatHistory.length > 0)
			{
				store.dispatch(chatActions.clearChat());
				store.dispatch(chatActions.addChatHistory(chatHistory));
			}

			(fileHistory.length > 0) && store.dispatch(
				fileActions.addFileHistory(fileHistory));

			if (lastNHistory.length > 0)
			{
				logger.debug('_joinRoom() | got lastN history');

				this._spotlights.addSpeakerList(
					lastNHistory.filter((peerId) => peerId !== this._peerId)
				);
			}

			locked ?
				store.dispatch(roomActions.setRoomLocked()) :
				store.dispatch(roomActions.setRoomUnLocked());

			(lobbyPeers.length > 0) && lobbyPeers.forEach((peer) =>
			{
				store.dispatch(
					lobbyPeerActions.addLobbyPeer(peer.id));
				store.dispatch(
					lobbyPeerActions.setLobbyPeerDisplayName(peer.displayName, peer.id));
				store.dispatch(
					lobbyPeerActions.setLobbyPeerPicture(peer.picture, peer.id));
			});

			(accessCode != null) && store.dispatch(
				roomActions.setAccessCode(accessCode));

			// Don't produce if explicitly requested to not to do it.
			if (this._produce)
			{
				if (this._mediasoupDevice.canProduce('audio'))
				{
					if (this._joinWithAudio)
					{
						await this.updateMic({ start: true });
						let autoMuteThreshold = -1;

						if ('autoMuteThreshold' in window.config)
						{
							autoMuteThreshold = window.config.autoMuteThreshold;
						}
						if (autoMuteThreshold >= 0 && peers.length >= autoMuteThreshold)
							this.muteMic();
					}
					else if (this._device.flag === 'safari')
					{
						await this.updateMic({ start: true });
						this.muteMic();
					}
				}					

				this.updateWebcam({ start: this._joinWithVideo});
			}

			await this._updateAudioOutputDevices();
			await this._updateAudioDevices();
			const { selectedAudioOutputDevice } = store.getState().settings;

			if (!selectedAudioOutputDevice && this._audioOutputDevices !== {})
			{
				store.dispatch(
					settingsActions.setSelectedAudioOutputDevice(
						Object.keys(this._audioOutputDevices)[0]
					)
				);
			}

			store.dispatch(roomActions.setRoomState('connected'));

			// Clean all the existing notifications.
			store.dispatch(notificationActions.removeAllNotifications());

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.joined',
						defaultMessage : 'You have joined the room'
					})
				}));

			this._spotlights.start();
		}
		catch (error)
		{
			logger.error('_joinRoom() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantJoin',
						defaultMessage : 'Unable to join the room'
					})
				}));

			this.close();
		}
	}

	handleActiveSpeaker(peerId)
	{
		if (!this._startRecord)
			return;
		if (peerId === this._peerId)
		{
			this._merger.addText(store.getState().settings.displayName);
		}
		else
		{
			const { displayName } = store.getState().peers[peerId];
			if (displayName)
				this._merger.addText(displayName);
		}
		if (peerId && peerId !== this._peerId)
		{
			if (this._activeSpeakerPeerId !== peerId) {
				const consumerId = this._peerAudioMap.get(peerId);
				if (consumerId && consumerId !== null) {
					const consumer = this._consumers.get(consumerId);
					if (consumer && consumer !== null)
					{
						this._addAudioMergerStream(consumer.track)
					}
						
				}
				
				if (this._recordingMode() !== 'democratic') {
					if (this._consumers && this._consumers.size > 0) {
						let videoStream = null;
						let screenStream = null;
						let videoStreamId = null;
						let screenStreamId = null;
						this._consumers.forEach((value, key) => {
							let consumer = value;
							if (consumer && consumer._appData.peerId === peerId && consumer.kind !== 'audio') {
								if (consumer._appData.source === 'screen'){
									screenStream =  new MediaStream();
									screenStream.addTrack(consumer.track)
									screenStreamId =  consumer.id;
								} else if (consumer._appData.source === 'webcam'){
									videoStream =  new MediaStream();
									videoStream.addTrack(consumer.track)
									videoStreamId = consumer.id;
								}
							}
						})
						if (screenStream !== null) {
							this._merger.addStream(
								{
									id: screenStreamId, 
									mediaStream: screenStream,
									opts:
										{
											mute: true
										},
									clear: true
								}
							);
						} else if (videoStream != null) {
							this._merger.addStream(
								{
									id: videoStreamId, 
									mediaStream: videoStream,
									opts:
										{
											mute: true
										},
									clear: true
								}
							);
						}
					}
				}
			}
		
		} else if (peerId === this._peerId && peerId !== this._activeSpeakerPeerId) {
			if (this._micProducer && this._micProducer !== null) {
				this._addAudioMergerStream(this._micProducer.track)
			}
			if (this._recordingMode() !== 'democratic') {
				let videoTrack = null;
		
				if (this._webcamProducer && this._webcamProducer !== null) 
					videoTrack = this._webcamProducer.track;
		
				if (this._screenSharingProducer && this._screenSharingProducer !== null) {
					const screenSharingStream = new MediaStream();
					screenSharingStream.addTrack(this._screenSharingProducer.track);
					this._merger.addStream(
						{
							id: 'localScreenShare',
							mediaStream: screenSharingStream , 
							opts: {mute: true}, 
							clear: true
					});
				} else {
					if (videoTrack && videoTrack !== null) 
					{
						const videoStream = new MediaStream();
						videoStream.addTrack(videoTrack);
						this._merger.addStream(
							{
								id: 'localVideo',
								mediaStream: videoStream , 
								opts: {mute: true}, 
								clear: true
							});
					}
				}
			}
		}		
		this._activeSpeakerPeerId = peerId;
	}

	async lockRoom()
	{
		logger.debug('lockRoom()');

		try
		{
			await this.sendRequest('lockRoom');

			store.dispatch(
				roomActions.setRoomLocked());

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.youLocked',
						defaultMessage : 'You locked the room'
					})
				}));
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantLock',
						defaultMessage : 'Unable to lock the room'
					})
				}));

			logger.error('lockRoom() [error:"%o"]', error);
		}
	}

	async unlockRoom()
	{
		logger.debug('unlockRoom()');

		try
		{
			await this.sendRequest('unlockRoom');

			store.dispatch(
				roomActions.setRoomUnLocked());

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.youUnLocked',
						defaultMessage : 'You unlocked the room'
					})
				}));
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantUnLock',
						defaultMessage : 'Unable to unlock the room'
					})
				}));

			logger.error('unlockRoom() [error:"%o"]', error);
		}
	}
	//Update by chinhnv
	async roomStartRecord()
	{
		if (!this.isSupportRecord) {
			logger.warn('Your browser is not supported!');
			return;
		}

		logger.debug('roomStartRecord');

		try
		{
			//await this.sendRequest('unlockRoom');
			
			store.dispatch(
				roomActions.setRoomStartRecord());
			
			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.startRecord',
						defaultMessage : 'You start recording'
					})
				}));
			this._startRecording();
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantStartRecord',
						defaultMessage : 'Unable to start recording'
					})
				}));
			logger.error('roomStartRecord [error:"%o"]', error);
		}
	}

	async roomStopRecord()
	{
		if (!this._startRecord) {
			logger.debug('Record not start.');
			return;
		}

		logger.debug('roomStopRecord');

		try
		{
			store.dispatch(
				roomActions.setRoomStopRecord());
			
			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.stopRecord',
						defaultMessage : 'You stop recording'
					})
				}));
			this._stopRecording();
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantStopRecord',
						defaultMessage : 'Unable to stop recording'
					})
				}));

			logger.error('roomStopRecord [error:"%o"]', error);
		}
	}

	async setAccessCode(code)
	{
		logger.debug('setAccessCode()');

		try
		{
			await this.sendRequest('setAccessCode', { accessCode: code });

			store.dispatch(
				roomActions.setAccessCode(code));

			store.dispatch(requestActions.notify(
				{
					text : 'Access code saved.'
				}));
		}
		catch (error)
		{
			logger.error('setAccessCode() [error:"%o"]', error);
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Unable to set access code.'
				}));
		}
	}

	async setJoinByAccessCode(value)
	{
		logger.debug('setJoinByAccessCode()');

		try
		{
			await this.sendRequest('setJoinByAccessCode', { joinByAccessCode: value });

			store.dispatch(
				roomActions.setJoinByAccessCode(value));

			store.dispatch(requestActions.notify(
				{
					text : `You switched Join by access-code to ${value}`
				}));
		}
		catch (error)
		{
			logger.error('setAccessCode() [error:"%o"]', error);
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Unable to set join by access code.'
				}));
		}
	}

	async addExtraVideo(videoDeviceId)
	{
		logger.debug(
			'addExtraVideo() [videoDeviceId:"%s"]',
			videoDeviceId
		);

		store.dispatch(
			roomActions.setExtraVideoOpen(false));

		if (!this._mediasoupDevice.canProduce('video'))
		{
			logger.error('addExtraVideo() | cannot produce video');

			return;
		}

		let track;

		store.dispatch(
			meActions.setWebcamInProgress(true));

		try
		{
			const device = this._webcams[videoDeviceId];
			const resolution = store.getState().settings.resolution;

			if (!device)
				throw new Error('no webcam devices');

			const stream = await navigator.mediaDevices.getUserMedia(
				{
					video :
					{
						deviceId : { ideal: videoDeviceId },
						...VIDEO_CONSTRAINS[resolution]
					}
				});

			([ track ] = stream.getVideoTracks());

			let producer;

			if (this._useSimulcast)
			{
				// If VP9 is the only available video codec then use SVC.
				const firstVideoCodec = this._mediasoupDevice
					.rtpCapabilities
					.codecs
					.find((c) => c.kind === 'video');

				let encodings;

				if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9')
					encodings = VIDEO_KSVC_ENCODINGS;
				else if ('simulcastEncodings' in window.config)
					encodings = window.config.simulcastEncodings;
				else
					encodings = VIDEO_SIMULCAST_ENCODINGS;

				producer = await this._sendTransport.produce(
					{
						track,
						encodings,
						codecOptions :
						{
							videoGoogleStartBitrate : 1000
						},
						appData :
						{
							source : 'extravideo'
						}
					});
			}
			else
			{
				producer = await this._sendTransport.produce({
					track,
					appData :
					{
						source : 'extravideo'
					}
				});
			}

			this._extraVideoProducers.set(producer.id, producer);

			store.dispatch(producerActions.addProducer(
				{
					id            : producer.id,
					deviceLabel   : device.label,
					source        : 'extravideo',
					paused        : producer.paused,
					track         : producer.track,
					rtpParameters : producer.rtpParameters,
					codec         : producer.rtpParameters.codecs[0].mimeType.split('/')[1]
				}));

			// store.dispatch(settingsActions.setSelectedWebcamDevice(deviceId));

			await this._updateWebcams();

			producer.on('transportclose', () =>
			{
				this._extraVideoProducers.delete(producer.id);

				producer = null;
				this._removeMergerStream('extravideo');
			});

			producer.on('trackended', () =>
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'devices.cameraDisconnected',
							defaultMessage : 'Camera disconnected'
						})
					}));

				this.disableExtraVideo(producer.id)
					.catch(() => {});
				this._removeMergerStream('extravideo');
			});
			this._addVideoMergerStream({id: 'extravideo', track: producer.track});
			logger.debug('addExtraVideo() succeeded');
		}
		catch (error)
		{
			logger.error('addExtraVideo() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.cameraError',
						defaultMessage : 'An error occurred while accessing your camera'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(
			meActions.setWebcamInProgress(false));
	}

	async disableMic()
	{
		logger.debug('disableMic()');

		if (!this._micProducer)
			return;

		store.dispatch(meActions.setAudioInProgress(true));

		this._micProducer.close();

		store.dispatch(
			producerActions.removeProducer(this._micProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._micProducer.id });
		}
		catch (error)
		{
			logger.error('disableMic() [error:"%o"]', error);
		}

		this._micProducer = null;

		store.dispatch(meActions.setAudioInProgress(false));
	}

	async updateScreenSharing({
		start = false,
		newResolution = null,
		newFrameRate = null
	} = {})
	{
		logger.debug('updateScreenSharing() [start:"%s"]', start);
		let track;		
		try
		{
			const available = this._screenSharing.isScreenShareAvailable();

			if (!available)
				throw new Error('screen sharing not available');

			if (!this._mediasoupDevice.canProduce('video'))
				throw new Error('cannot produce video');

			if (newResolution)
				store.dispatch(settingsActions.setScreenSharingResolution(newResolution));

			if (newFrameRate)
				store.dispatch(settingsActions.setScreenSharingFrameRate(newFrameRate));

			const {
				screenSharingResolution,
				screenSharingFrameRate
			} = store.getState().settings;

			store.dispatch(meActions.setScreenShareInProgress(true));
			
			if (start)
			{
				this._screenSharingProducer = await this._addProducer('screen');

				store.dispatch(producerActions.addProducer(
					{
						id            : this._screenSharingProducer.id,
						deviceLabel   : 'screen',
						source        : 'screen',
						paused        : this._screenSharingProducer.paused,
						track         : this._screenSharingProducer.track,
						rtpParameters : this._screenSharingProducer.rtpParameters,
						codec         : this._screenSharingProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
					}));
				
				// this.setSelectedPeer(this._screenSharingProducer.id);

				this._screenSharingProducer.on('transportclose', () =>
				{
					this._screenSharingProducer = null;
					this._removeMergerStream('localScreenShare');
				});

				this._screenSharingProducer.on('trackended', () =>
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'devices.screenSharingDisconnected',
								defaultMessage : 'Screen sharing disconnected'
							})
						}));

					this.disableScreenSharing();
				});
				this._addVideoMergerStream({id: 'localScreenShare', track: this._screenSharingProducer.track, isScreen: true});
				
			}
			else if (this._screenSharingProducer)
			{
				({ track } = this._screenSharingProducer);

				await track.applyConstraints(
					{
						...VIDEO_CONSTRAINS[screenSharingResolution],
						frameRate : screenSharingFrameRate
					}
				);
				this._addVideoMergerStream({id: 'localScreenShare', track: track, isScreen: true});
			}
			/*
			if(store.getState().room.mode === LayoutMode.DEMOCRATIC) {
				store.dispatch(roomActions.setDisplayMode(LayoutMode.FILMSTRIP));
				this.updateVirtualBackground();
			}*/

		}
		catch (error)
		{
			logger.error('updateScreenSharing() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.screenSharingError',
						defaultMessage : 'An error occurred while accessing your screen'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(meActions.setScreenShareInProgress(false));
	}

	async disableScreenSharing()
	{
		logger.debug('disableScreenSharing()');

		if (!this._screenSharingProducer)
			return;

		store.dispatch(meActions.setScreenShareInProgress(true));

		this._screenSharingProducer.close();

		store.dispatch(
			producerActions.removeProducer(this._screenSharingProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._screenSharingProducer.id });
		}
		catch (error)
		{
			logger.error('disableScreenSharing() [error:"%o"]', error);
		}

		this._screenSharingProducer = null;

		this._screenSharing.stop();

		store.dispatch(meActions.setScreenShareInProgress(false));

		this._removeMergerStream('localScreenShare');

		store.dispatch(roomActions.setCanSharescreenVideo(true));
		/*
		const { sharescreenId } = store.getState().room;
		if(sharescreenId) {
			store.dispatch(roomActions.setSharescreenVideo(null))
		}
		if(store.getState().room.mode === LayoutMode.FILMSTRIP) {
			store.dispatch(roomActions.setDisplayMode(LayoutMode.DEMOCRATIC));
			this.updateVirtualBackground(false)
		}
		*/
	}

	async disableExtraVideo(id)
	{
		logger.debug('disableExtraVideo()');

		const producer = this._extraVideoProducers.get(id);

		if (!producer)
			return;

		store.dispatch(meActions.setWebcamInProgress(true));

		producer.close();

		store.dispatch(
			producerActions.removeProducer(id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: id });
		}
		catch (error)
		{
			logger.error('disableWebcam() [error:"%o"]', error);
		}

		this._extraVideoProducers.delete(id);

		store.dispatch(meActions.setWebcamInProgress(false));
		this._removeMergerStream('extraVideo');
	}

	async disableWebcam()
	{
		logger.debug('disableWebcam()');

		if (!this._webcamProducer)
			return;

		store.dispatch(meActions.setWebcamInProgress(true));

		store.dispatch(
			producerActions.removeProducer(this._webcamProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._webcamProducer.id });
		}
		catch (error)
		{
			logger.error('disableWebcam() [error:"%o"]', error);
		}
		
		if (streamBlurEffect && streamBlurEffect.isStartEffect())
		{
			streamBlurEffect.stopEffect();
		}

		this._webcamProducer.track.stop();

		if (this._videoStream && this._videoStream !== null )
		{
			this._videoStream.getTracks()[0].stop();
			this._videoStream = null;
		}

		this._webcamProducer.close();

		this._webcamProducer = null;

		store.dispatch(meActions.setWebcamInProgress(false));
		this._removeMergerStream('localVideo');
	}

	async _setNoiseThreshold(threshold)
	{
		logger.debug('_setNoiseThreshold() [threshold:"%s"]', threshold);

		this._hark.setThreshold(threshold);

		store.dispatch(
			settingsActions.setNoiseThreshold(threshold));
	}

	async _updateAudioDevices()
	{
		logger.debug('_updateAudioDevices()');

		// Reset the list.
		this._audioDevices = {};

		try
		{
			logger.debug('_updateAudioDevices() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'audioinput')
					continue;

				this._audioDevices[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setAudioDevices(this._audioDevices));
		}
		catch (error)
		{
			logger.error('_updateAudioDevices() [error:"%o"]', error);
		}
	}

	async _updateWebcams()
	{
		logger.debug('_updateWebcams()');
		// Reset the list.
		this._webcams = {};

		try
		{
			logger.debug('_updateWebcams() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'videoinput')
					continue;

				this._webcams[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setWebcamDevices(this._webcams));
		}
		catch (error)
		{
			logger.error('_updateWebcams() [error:"%o"]', error);
		}
	}

	async _getAudioDeviceId()
	{
		logger.debug('_getAudioDeviceId()');

		try
		{
			logger.debug('_getAudioDeviceId() | calling _updateAudioDeviceId()');

			await this._updateAudioDevices();

			const { selectedAudioDevice } = store.getState().settings;

			if (selectedAudioDevice && this._audioDevices[selectedAudioDevice])
				return selectedAudioDevice;
			else
			{
				const audioDevices = Object.values(this._audioDevices);

				return audioDevices[0] ? audioDevices[0].deviceId : null;
			}
		}
		catch (error)
		{
			logger.error('_getAudioDeviceId() [error:"%o"]', error);
		}
	}

	async _getWebcamDeviceId()
	{
		logger.debug('_getWebcamDeviceId()');

		try
		{
			logger.debug('_getWebcamDeviceId() | calling _updateWebcams()');

			await this._updateWebcams();

			const { selectedWebcam } = store.getState().settings;

			if (selectedWebcam && this._webcams[selectedWebcam])
				return selectedWebcam;
			else
			{
				const webcams = Object.values(this._webcams);

				return webcams[0] ? webcams[0].deviceId : null;
			}
		}
		catch (error)
		{
			logger.error('_getWebcamDeviceId() [error:"%o"]', error);
		}
	}

	async _updateAudioOutputDevices()
	{
		logger.debug('_updateAudioOutputDevices()');

		// Reset the list.
		this._audioOutputDevices = {};

		try
		{
			logger.debug('_updateAudioOutputDevices() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'audiooutput')
					continue;

				this._audioOutputDevices[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setAudioOutputDevices(this._audioOutputDevices));
		}
		catch (error)
		{
			logger.error('_updateAudioOutputDevices() [error:"%o"]', error);
		}
	}

	async _startRecording()
	{
		const recordingConfig = window.config.recording;
		const {
			resolution,
			screenSharingResolution
		} = store.getState().settings;

		const rScreenSharing = VIDEO_CONSTRAINS[screenSharingResolution];
		const rVideo = VIDEO_CONSTRAINS[resolution];
		this._merger = new RecordingVideoStreamMerger({
			width: recordingConfig.width, 
			height: recordingConfig.height,
			fps: recordingConfig.frameRate,
			clearRect: true
			},
			store.getState().settings.displayName,
			rVideo,
			rScreenSharing
			);

		this._startRecord = true;

		await this.calculateSize();

		if (this._intMergerStream())
		{
			this._merger.start();
			//this._mediaRecorder = new MediaRecorder(this._merger.result, options);

			this._audioRecord = RecordRTC(
				this._merger.result, 
				audioOptions
			);

			//splitIn
			this._videoRecord = RecordRTC(
				this._merger.result, 
				options
			);

			if (recordingConfig.splitIn && recordingConfig.splitIn !== null) {
				this._audioRecord.setRecordingDuration(recordingConfig.splitIn * 1000 * 60).onRecordingStopped(this._downLoadAudioRecordAndRestart.bind(this));
				this._videoRecord.setRecordingDuration(recordingConfig.splitIn * 1000 * 60).onRecordingStopped(this._downLoadVideoRecordAndRestart.bind(this));
			}
			
			this._videoRecord.startRecording();
			this._audioRecord.startRecording();
		}
	}

	_downLoadAudioRecordAndRestart()
	{
		var d = new Date();
		if(!this._audioRecord || !this._audioRecord.getBlob()) return;
		// to fix video seeking issues
		RecordRTC.getSeekableBlob(this._audioRecord.getBlob(), function(seekableBlob) {
			RecordRTC.invokeSaveAsDialog(seekableBlob, `audio_${d.getTime()}.webm`);
		});
		
		this._audioRecord.reset();
		this._audioRecord.startRecording();
	}

	_downLoadVideoRecordAndRestart(){
		var d = new Date();
		if(!this._videoRecord || !this._videoRecord.getBlob()) return;
		// to fix video seeking issues
		RecordRTC.getSeekableBlob(this._videoRecord.getBlob(), function(seekableBlob) {
			RecordRTC.invokeSaveAsDialog(seekableBlob, `video_${d.getTime()}.webm`);
		});
		//RecordRTC.invokeSaveAsDialog(this._videoRecord.getBlob(), `${d.getTime()}.webm`);		
		this._videoRecord.reset();
		this._videoRecord.startRecording();
	}

	_downLoadAudioRecord(){
		var d = new Date();
		if(!this._audioRecord || !this._audioRecord.getBlob()) return;		

		// to fix video seeking issues
		RecordRTC.getSeekableBlob(this._audioRecord.getBlob(), function(seekableBlob) {
			RecordRTC.invokeSaveAsDialog(seekableBlob, `audio_${d.getTime()}.webm`);
		});
		this._audioRecord.destroy();
	}

	_downLoadVideoRecord(){
		var d = new Date();
		if(!this._videoRecord || !this._videoRecord.getBlob()) return;

		//RecordRTC.invokeSaveAsDialog(this._videoRecord.getBlob(), `${d.getTime()}.webm`);
		// to fix video seeking issues
		RecordRTC.getSeekableBlob(this._videoRecord.getBlob(), function(seekableBlob) {
			RecordRTC.invokeSaveAsDialog(seekableBlob, `video_${d.getTime()}.webm`);
		});
		this._videoRecord.destroy();
		if (this._isClosing){
			this.close();
		}
	}

	async _stopRecording()
	{
		this._startRecord = false;

		await this.calculateSize();

		if (this._audioRecord && this._audioRecord !== null)
		{
			this._audioRecord.stopRecording(this._downLoadAudioRecord.bind(this));
		}

		if (this._videoRecord && this._videoRecord !== null)
		{
			this._videoRecord.stopRecording(this._downLoadVideoRecord.bind(this));
		}

		if (this._merger)
		{
			this._merger.destroy();
			this._merger = null;

		}
		
	}

	_setRecordLocal(all = true, clear = false) {
		let videoTrack = null;
		let audioTrack = null;
		if (this._webcamProducer && this._webcamProducer !== null) 
			videoTrack = this._webcamProducer.track;
		
		if (this._micProducer && this._micProducer !== null)
			audioTrack = this._micProducer.track;

		
		if (audioTrack !== null) 
		{
			this._addAudioMergerStream(audioTrack);
		}

		const { displayName } = store.getState().settings;
		if (this._screenSharingProducer && this._screenSharingProducer !== null) {
			const screenSharingStream = new MediaStream();
			screenSharingStream.addTrack(this._screenSharingProducer.track);
			this._merger.addStream(
				{
					id: 'localScreenShare', 
					mediaStream: screenSharingStream , 
					opts: {mute: true}, 
					clear: clear,
					isScreen: true,
					displayName: displayName,
					peerId: this._peerId
				}
			);
		}

		if (videoTrack !== null && (all || this._screenSharingProducer === null)) 
		{
			const videoStream = new MediaStream();
			videoStream.addTrack(videoTrack);
			this._merger.addStream(
				{
					id: 'localVideo',
					mediaStream: videoStream,
					opts: {mute: true},
					clear: clear,
					displayName: displayName,
					peerId: this._peerId
				}
			);
		}
	}

	_intMergerStream() 
	{
		try 
		{
			if (!this._startRecord)
				return true;
			let peerId,displayName;
			if (this._recordingMode() === 'democratic') {
				if (this._consumers && this._consumers.size > 0) {
					this._consumers.forEach((value, key) => {
						let consumer = value;
						if (consumer && consumer.kind !== 'audio') {
							const videoStream = new MediaStream();
							videoStream.addTrack(consumer.track);
							peerId = consumer.appData.peerId;
							displayName = store.getState().peers[peerId].displayName;
							this._merger.addStream(
								{
									id: consumer.id, 
									mediaStream: videoStream, 
									opts: {
										mute: true
									},
									isScreen: (consumer._appData.source === 'screen'),
									displayName: displayName,
									peerId: peerId
								}
							);
						}
					})
				}
				this._setRecordLocal();
				return true;
			} 
			
			if ((this._activeSpeakerPeerId === this._peerId) || this._consumers.size === 0) {
				this._setRecordLocal(false, true);
				return true;
			}
			let cnt = 0;
			if ( this._consumers.size > 0 && this._activeSpeakerPeerId != null) {
				const consumerId = this._peerAudioMap.get(this._activeSpeakerPeerId);
				if (consumerId && consumerId !== null)
				{
					const consumer = this._consumers.get(consumerId);
					if (consumer && consumer !== null)
					{
						this._addAudioMergerStream(consumer.track);
						cnt ++;
					}
				}

				let videoStream = null;
				let screenStream = null;
				let videoStreamId = null;
				let screenStreamId = null;
				this._consumers.forEach((value, key) => {
					let consumer = value;
					if (consumer && consumer._appData.peerId === this._activeSpeakerPeerId && consumer.kind !== 'audio')
					{
						peerId = consumer.appData.peerId;
						displayName = store.getState().peers[peerId].displayName;
						if (consumer._appData.source === 'screen')
						{
							screenStream =  new MediaStream();
							screenStream.addTrack(consumer.track);
							screenStreamId = consumer.id;
						}
						else if (consumer._appData.source === 'webcam')
						{
							videoStream =  new MediaStream();
							videoStream.addTrack(consumer.track);
							videoStreamId = consumer.id;
						}
					}
				})

				if (screenStream !== null) {
					this._merger.addStream(
						{
							id: screenStreamId, 
							mediaStream: screenStream, 
							opts: {
								mute: true
							},
							clear: true,
							displayName: displayName,
							peerId: peerId
						}
					);
					cnt ++
				} else if (videoStream != null) {
					this._merger.addStream(
						{
							id: videoStreamId, 
							mediaStream: videoStream, 
							opts: {
								mute: true
							},
							clear: true,
							displayName: displayName,
							peerId: peerId
						}
					);
					cnt ++
				}

				if (cnt > 0)
					return true;
			}
			this._setRecordLocal(false, true);
		} 
		catch (error) 
		{
			logger.error('Exception while creating MediaRecorder:', error);
			return false;
		}
		
		return true;
	}
	async _addVideoMergerStream({id, track, isScreen = false, name = null, peerId = null})
	{
		if (this._merger === null || !this._startRecord)
			return;
		
		const videoStream = new MediaStream();
		videoStream.addTrack(track);
		const { displayName } = store.getState().settings;
		if (this._recordingMode() === 'democratic') {
			this._merger.addStream(
				{
					id: id, 
					mediaStream: videoStream, 
					opts: {
						mute: true
					},
					isScreen: isScreen,
					displayName: name === null ? displayName : name,
					peerId: peerId === null ? this._peerId: peerId
				});
		}
	}

	async _addAudioMergerStream(audioTrack)
	{
		if (this._merger === null || !this._startRecord)
			return;

		if (this._currentMode !== this._recordingMode()){
			this._currentMode = this._recordingMode();
			this._intMergerStream();
		} else {
			const audioStream = new MediaStream();
			audioStream.addTrack(audioTrack);
			this._merger.addStream(
				{
					id: AUDIO_MERGER_KEY, 
					mediaStream: audioStream, 
					opts: {mute: false}
				}
			);
		}
	}

	changeDisplayMode(mode, init = true) 
	{
		if (store.getState().room.mode !== mode)
		{
			store.dispatch(roomActions.setDisplayMode(mode));
			if (store.getState().room.mode === 'democratic')
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'room.setDemocraticView',
							defaultMessage : 'Changed layout to democratic view'
						})
					}));
			}
			else
			{
				store.dispatch(roomActions.setDisplayMode('filmstrip'));
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'room.setFilmStripView',
							defaultMessage : 'Changed layout to filmstrip view'
						})
					}));
			}
			if (init)
			{
				this._intMergerStream();
			}				
			this._currentMode = mode;
			this.calculateSize();
		}
		this.updateVirtualBackground();
	}

	async _removeMergerStream(id)
	{
		if (this._merger === null || !this._startRecord)
			return;
		this._merger.removeStream(id);
	}

	async _addProducer(type)
	{
		let codec = '';
		let track;
		let _producer;
		let resolution, frameRate;
		if (window.config.showSettings)
		{
			resolution = store.getState().settings.resolution;
			frameRate =  store.getState().settings.frameRate;
		}
		else
		{
			resolution = window.config.defaultResolution;
			frameRate =  window.config.defaultFrameRate;
		}

		if (window.config.forceH264)
		{
			codec = this._mediasoupDevice.rtpCapabilities.codecs
				.find((c) => c.mimeType.toLowerCase() === 'video/h264');
		}
		else if (window.config.forceVP9)
		{
			codec = this._mediasoupDevice.rtpCapabilities.codecs
				.find((c) => c.mimeType.toLowerCase() === 'video/vp9');
		}

		const {
			screenSharingResolution,
			screenSharingFrameRate
		} = store.getState().settings;

		if (type === 'screen') 
		{
			const stream = await this._screenSharing.start({
				...VIDEO_CONSTRAINS[screenSharingResolution],
				frameRate : screenSharingFrameRate
			});
			
			([ track ] = stream.getVideoTracks());

			if (this._useSharingSimulcast)
			{
				// If VP9 is the only available video codec then use SVC.
				const firstVideoCodec = this._mediasoupDevice
					.rtpCapabilities
					.codecs
					.find((c) => c.kind === 'video');

				let encodings;

				if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9')
				{
					encodings = VIDEO_SVC_ENCODINGS;
				}
				else if ('simulcastEncodings' in window.config)
				{
					encodings = window.config.simulcastEncodings
						.map((encoding) => ({ ...encoding, dtx: true }));
				}
				else
				{
					encodings = VIDEO_SIMULCAST_ENCODINGS
						.map((encoding) => ({ ...encoding, dtx: true }));
				}

				_producer = await this._sendTransport.produce(
					{
						track,
						encodings,
						codecOptions :
						{
							videoGoogleStartBitrate : 1000
						},
						codec,
						appData :
						{
							source : 'screen'
						}
					});
			}
			else
			{
				_producer = await this._sendTransport.produce({
					track,
					codec,
					appData :
					{
						source : 'screen'
					}
				});
			}
		}
		else if (type === 'webcam')
		{
			const deviceId = await this._getWebcamDeviceId();

			const device = this._webcams[deviceId];

			if (!device)
				throw new Error('no webcam devices');
				
			this._videoStream = await navigator.mediaDevices.getUserMedia(
				{
					video :
					{
						deviceId : { ideal: deviceId },
						...VIDEO_CONSTRAINS[resolution],
						frameRate
					}
				});
			
			([ track ] = this._videoStream.getVideoTracks());

			const { deviceId: trackDeviceId } = track.getSettings();
	
			store.dispatch(settingsActions.setSelectedWebcamDevice(trackDeviceId));
	
			if (this._useSimulcast)
			{
				// If VP9 is the only available video codec then use SVC.
				const firstVideoCodec = this._mediasoupDevice
					.rtpCapabilities
					.codecs
					.find((c) => c.kind === 'video');
	
				let encodings;
				if ((window.config.forceVP9 && codec) || firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') 
				{
					encodings = VIDEO_KSVC_ENCODINGS;
				}
				else if ('simulcastEncodings' in window.config)
					encodings = window.config.simulcastEncodings;
				else
					encodings = VIDEO_SIMULCAST_ENCODINGS;
				
				_producer = await this._sendTransport.produce(
				{
					track,
					encodings,
					codecOptions :
					{
						videoGoogleStartBitrate : 1000
					},
					codec,
					appData :
					{
						source : 'webcam'
					},
					stopTracks : false
				});
			}
			else
			{
				_producer = await this._sendTransport.produce({
					track,
					codec,
					appData :
					{
						source : 'webcam'
					},
					stopTracks : false
				});
			}
		}
		else
		{
			let track;
			([ track ]  = this._dummyStream.getVideoTracks());
			_producer = await this._sendTransport.produce({
				track,
				codec,
				appData :
				{
					source : 'webcam'
				},
				stopTracks : false
			});
		}
		return _producer;
	}
	_recordingMode()
	{
		// auto, democratic, filmstrip
		const recordingMode = window.config.recordingMode;
		if (recordingMode === 'auto')
		{
			return store.getState().room.mode;
		}
		return recordingMode;
	}

	_onUnload(event){
		if (this._startRecord) {
			var millisecondsToWait = 5000;
			this._stopRecording();
			setTimeout(function() {
				logger.infor('Stoped recording.');
			}, millisecondsToWait);
		}
	}

	updateVirtualBackground()
	{
		if (streamBlurEffect && !streamBlurEffect.isStartEffect() && this._webcamProducer && store.getState().settings.virtualBackground)
		{
			streamBlurEffect.startEffect(this._webcamProducer.track, 'canvasVideo', store.getState().settings.virtualBackground)
		}

		if (streamBlurEffect && !store.getState().settings.virtualBackground)
		{
			streamBlurEffect.setIsBlur(store.getState().settings.virtualBackground);
			if (streamBlurEffect.isStartEffect())
				streamBlurEffect.stopEffect();
		}

	}

	getCanvas()
	{
		if (this._emptyCanvas === null)
		{
			this._emptyCanvas = document.createElement('canvas');
			this._emptyCanvas.width = 640;
			this._emptyCanvas.height = 480;
			this.drawDummy();
			this.startAnimating(5);
			this._dummyStream = this._emptyCanvas.captureStream(5);
		}
	}

	drawDummy()
	{
		const { displayName } = store.getState().settings;
		const ctx =  this._emptyCanvas.getContext("2d");
		ctx.fillStyle = 'black';
		ctx.fillRect(0, 0, this._emptyCanvas.width, this._emptyCanvas.height);
		ctx.font = "30px Comic Sans MS";
		ctx.fillStyle = "white";
		ctx.textAlign = "center";
		ctx.fillText(displayName, this._emptyCanvas.width/2, this._emptyCanvas.height/2);
	}

	
	async createDumy()
	{
		const { displayName } = store.getState().settings;
		let black = ({width = 640, height = 480} = {}) => {
			const canvas = Object.assign(document.createElement("canvas"), {width, height});
			const ctx = canvas.getContext('2d');
			ctx.fillRect(0, 0, width, height);
			ctx.font = "30px Comic Sans MS";
			ctx.fillStyle = "white";
			ctx.textAlign = "center";
			ctx.fillText(displayName, canvas.width/2, canvas.height/2);
			let stream = canvas.captureStream();
			return Object.assign(stream.getVideoTracks()[0], {enabled: true});
		}
		let video = Object.assign(document.createElement("video"));
		video.autoplay = true;
        video.setAttribute('playsinline', '');
        video.muted = true;
		video.srcObject = new MediaStream([black()]);
		await video.play();
		this._dummyStream = video.srcObject;
	}

	async getLocalCamera(videoContraint) {

		let resolution, frameRate;
		if (window.config.showSettings)
		{
			resolution = store.getState().settings.resolution;
			frameRate = store.getState().settings.frameRate;
		}
		else
		{
			resolution = window.config.defaultResolution;
			frameRate =  window.config.defaultFrameRate;
		}

		const deviceId = await this._getWebcamDeviceId();

		const device = this._webcams[deviceId];

		if (!device)
		{
			const promise = new Promise(function(resolve, reject) {
				setTimeout(() => resolve(("Done")), 100);
			  }).then (() => {
					return null;
			  });
			return promise;
		}
		

		return navigator.mediaDevices.getUserMedia(
			{
				video :
				{
					deviceId : { ideal: deviceId },
					...videoContraint? videoContraint : VIDEO_CONSTRAINS[resolution],
					frameRate
				}
			});
	}
	startAnimating(fps) {
		fpsInterval = 1000 / fps;
		then = Date.now();
		this.onFrame();
	}
	
	
	onFrame = () => {
	
		// request another frame
	
		requestAnimationFrame(this.onFrame);
	
		// calc elapsed time since last loop
	
		now = Date.now();
		elapsed = now - then;
	
		// if enough time has elapsed, draw the next frame
	
		if (elapsed > fpsInterval) {
	
			// Get ready for next frame by setting then=now, but...
			// Also, adjust for fpsInterval not being multiple of 16.67
			then = now - (elapsed % fpsInterval);
	
			// draw stuff here
			this.drawDummy();
		}
	}


	calculateSize =  async () =>
	{
		const isSimulcast = this._useSimulcast;
		let maxReceiptBitrate = -1;
		if ('maxReceiptBitrate' in window.config)
		{
			maxReceiptBitrate = window.config.maxReceiptBitrate;
		}
		let simulcastEncodings = [];

		if ('simulcastEncodings' in window.config)
		{
			simulcastEncodings = window.config.simulcastEncodings;
		}
		let selectedSpatialLayer = -1;
		if ('selectedSpatialLayer' in window.config)
		{
			selectedSpatialLayer = window.config.selectedSpatialLayer;
		}
		const LOWEST_LEVEL = 0;
		let total = 0;
		if (isSimulcast && simulcastEncodings.length > 0)
		{
			const { sharescreenId, activeSpeakerId } = store.getState().room;	
			if (sharescreenId)
			{
				for (const consumer of this._consumers.values())
				{
					if (consumer.track.kind === 'video' && consumer.appData.source === 'webcam' && !consumer.paused)
					{
						this.setConsumerPreferredLayers(consumer, consumer.id, LOWEST_LEVEL);
					}
				}
				return;
			}		

			if (this._recvTransport)
			{
				
				if (selectedSpatialLayer > -1 && selectedSpatialLayer < simulcastEncodings.length)
				{
					for (const consumer of this._consumers.values())
					{
						if (consumer.track.kind === 'video' && consumer.appData.source === 'webcam' && !consumer.paused)
						{
							this.setConsumerPreferredLayers(consumer, consumer.id, selectedSpatialLayer);
						}
					}
				}
				else if (maxReceiptBitrate > 0)
				{
					// if (sendBitrate >= maxReceiptBitrate)
					// {
					// 	for (const consumer of this._consumers.values())
					// 	{
					// 		const { spatialLayers, temporalLayers } =
					// 			mediasoupClient.parseScalabilityMode(consumer.rtpParameters.encodings[0].scalabilityMode);
					// 		if (consumer.track.kind === 'video' && consumer.appData.source === 'webcam' && !consumer.paused)
					// 		{
					// 			if (spatialLayers > 0)
					// 			{
					// 				this.setConsumerPreferredLayers(consumer.id, spatialLayers - 1, temporalLayers -1);
					// 			}
					// 		}
					// 	}
					// }
					for (const consumer of this._consumers.values())
					{
						if (consumer.track.kind === 'video' && consumer.appData.source === 'webcam' && !consumer.paused)
						{
							total ++;
						}
					}
					if (total > 0) {
						const bitrate = maxReceiptBitrate / total;
						let level = 0;
						for (let index = simulcastEncodings.length - 1; index >=0 ; index--) {
							const simulcastEncoding = simulcastEncodings[index];
							if (!simulcastEncoding.estimateBitrate)
							{
								simulcastEncoding.estimateBitrate =  simulcastEncoding.maxBitrate;
							}
							if (bitrate >= simulcastEncoding.estimateBitrate)
							{
								level = index;
								break;
							}
						}
						for (const consumer of this._consumers.values())
						{
							if (consumer.track.kind === 'video' && consumer.appData.source === 'webcam' && !consumer.paused)
							{
								this.setConsumerPreferredLayers(consumer, consumer.id, level);
							}
						}
					}					
				}
				else if (maxReceiptBitrate === 0 && this._startRecord)
				{
					for (const consumer of this._consumers.values())
					{
						if (consumer.track.kind === 'video' && consumer.appData.source === 'webcam' && !consumer.paused)
						{
							total ++;
						}
					}
					if (total === 0)
						return;

					if (this._webcamProducer && this._webcamProducer != null)
					{
						total ++;
					}
					const {
						resolution
					} = store.getState().settings;

					const rate = VIDEO_CONSTRAINS[resolution].aspectRatio;

					const recordingConfig = window.config.recording;
					const rows = Math.round(Math.sqrt(total));			
					let width, height;

					const videoWidth = recordingConfig.width;
					const videoHeight = recordingConfig.height;
				
					let number = Math.round(total / rows);
					if (number * rows < total)
						number ++;
				
					width = Math.floor(videoWidth / number);
					height = Math.floor(width / rate);

					if ((height * rows) > videoHeight)
					{
						height = Math.floor(videoHeight/ rows);
						width = height * rate;
					}
					let index = 0;
					for (const consumer of this._consumers.values())
					{
						if (consumer.track.kind === 'video' && consumer.appData.source === 'webcam' && !consumer.paused)
						{
							let track = consumer.track.getConstraints();
							let realVideoWidth = 640;
							let realVideoHeight = 480;
							let spatialLayer = -1;

							if (track && track.width)
							{
								realVideoWidth = track.width;
								realVideoHeight = track.height;
							}
							else
							{
								track = consumer.track.getSettings();
								if (track && track.width)
								{
									realVideoWidth = track.width;
									realVideoHeight = track.height;
								}
							}
							
							let resolutionCheck;
							if (realVideoWidth > realVideoHeight)
								resolutionCheck = width;
							else
								resolutionCheck = height;

							let activeSpeakerCheck = false;

							if (store.getState().room.mode === 'filmstrip')
							{
								if (!activeSpeakerId &&  activeSpeakerId=== null)
								{
									activeSpeakerCheck = (index === 0)
								}
								else
								{
									activeSpeakerCheck = (consumer.appData.peerId === activeSpeakerId)
								}
							}
							//L1T3
							if (simulcastEncodings.length === 3)
							{
								if (resolutionCheck > 640 || activeSpeakerCheck)
								{
									spatialLayer = 2;
								}
								else if (resolutionCheck > 320)
								{
									spatialLayer = 1;
								}
								else
								{
									spatialLayer = 0;
								}
							}
							else if (simulcastEncodings.length === 2) //L1T2
							{
								if (resolutionCheck > 320 || activeSpeakerCheck)
								{
									spatialLayer = 1
								}
								else
								{
									spatialLayer = 0;
								}
							}
							if (spatialLayer >= 0)
							{
								this.setConsumerPreferredLayers(consumer, consumer.id, spatialLayer);
							}							
						}
						index ++;
					}
				}
			}
		} 
	}

	simulcastAutoChange =  async ({width , height, consumerId}) =>
	{
		let maxReceiptBitrate = -1;
		if ('maxReceiptBitrate' in window.config)
		{
			maxReceiptBitrate = window.config.maxReceiptBitrate;
		}
		let simulcastEncodings = [];

		if ('simulcastEncodings' in window.config)
		{
			simulcastEncodings = window.config.simulcastEncodings;
		}
		let selectedSpatialLayer = -1;
		if ('selectedSpatialLayer' in window.config)
		{
			selectedSpatialLayer = window.config.selectedSpatialLayer;
		}

		const { sharescreenId, activeSpeakerId } = store.getState().room;	

		if (!this._startRecord && this._useSimulcast && maxReceiptBitrate === 0 && selectedSpatialLayer === -1 && simulcastEncodings.length > 0 && sharescreenId === null)
		{
			let resolutionCheck;
			let spatialLayer = -1;

			if (width > height)
				resolutionCheck = width;
			else
				resolutionCheck = height;
			

			let activeSpeakerCheck = false;

			const consumer = this._consumers.get(consumerId);
			if (!consumer)
				return;

			let firstConsumer = null;

			for (const mConsumer of this._consumers.values())
			{
				if (mConsumer.track.kind === 'video' && mConsumer.appData.source === 'webcam' && !mConsumer.paused && firstConsumer === null)
				{
					firstConsumer = mConsumer;
					break;
				}
			}

			if (store.getState().room.mode === 'filmstrip')
			{
				if (!activeSpeakerId &&  activeSpeakerId=== null)
				{
					activeSpeakerCheck = (firstConsumer.id === consumer.id)
				}
				else
				{
					activeSpeakerCheck = (consumer.appData.peerId === activeSpeakerId)
				}
			}

			//L1T3
			if (simulcastEncodings.length === 3)
			{
				if (resolutionCheck > 640 || activeSpeakerCheck)
				{
					spatialLayer = 2;
				}
				else if (resolutionCheck > 320)
				{
					spatialLayer = 1;
				}
				else
				{
					spatialLayer = 0;
				}
			}
			else if (simulcastEncodings.length === 2) //L1T2
			{
				if (resolutionCheck > 320 || activeSpeakerCheck)
				{
					spatialLayer = 1
				}
				else
				{
					spatialLayer = 0;
				}
			}
			if (spatialLayer >= 0)
			{
				this.setConsumerPreferredLayers(consumer, consumerId, spatialLayer);
			}	
		}
	}
}
