import Logger from './Logger';
import {StereoAudioRecorder, MediaStreamRecorder} from 'recordrtc';

const logger = new Logger('recordInfo');

export default function()
{
	const isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	let options = {mimeType: 'video/webm;codecs=vp9,opus'};
	//let options = {};
	let flag = true;
	try {
		if (!MediaRecorder.isTypeSupported(options.mimeType)) {
			logger.debug(`${options.mimeType} is not supported`);
			options = {mimeType: 'video/webm;codecs=vp8,opus'};
			if (!MediaRecorder.isTypeSupported(options.mimeType)) {
				logger.error(`${options.mimeType} is not supported`);
				options = {mimeType: 'video/webm'};
				if (!MediaRecorder.isTypeSupported(options.mimeType)) {
					logger.error(`${options.mimeType} is not supported`);
					options = {mimeType: 'video/webm'};
					flag = true;
				}
			}			
		}
		options.bitsPerSecond = window.config.recording.bitsPerSecond || 10485760;
		options.type = 'video';
		options.recorderType = MediaStreamRecorder;
		options.videoBitsPerSecond = window.config.recording.bitsPerSecond || 10485760;

		var audioOptions = {
			type: 'audio',
			numberOfAudioChannels: isEdge ? 1 : 2,
			checkForInactiveTracks: true,
			bufferSize: 16384
		};

		if(isSafari || isEdge)
		{
			audioOptions.recorderType = StereoAudioRecorder;
		}

		if(navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1)
		{
			audioOptions.sampleRate = 48000; // or 44100 or remove this line for default
		}

		if(isSafari)
		{
			audioOptions.sampleRate = 44100;
			audioOptions.bufferSize = 4096;
			audioOptions.numberOfAudioChannels = 2;
		}
	} catch (error) {
		logger.error(`Your bowser is not supported`);
		flag = false;
	}

	return {
		flag,
		options  : options, // recording options
		audioOptions: audioOptions
	};
}