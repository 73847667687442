// reducer
//import { FETCH_GUEST_SUCCESS } from '../actions/fetchDataActions';

const initialState = {
    products: [],
    dataInitGuest: [],
}

// export function fetchDataReducers(state = initialState, action) {
//     switch(action.type) {
//         case FETCH_GUEST_SUCCESS:
//             return {
//                 ...state,
//                 products: action.payload
//             }
//         default:
//             return state;
//     }
// }

const fetchDatainit = (state = initialState, action) => {
    switch(action.type){
        case 'SET_DATA_INIT_GUEST': 
        {
            const { dataInitGuest } = action.payload;
            return {...state, dataInitGuest };
        }
        default:
            return state;
    };
};

export default fetchDatainit;
