/* globals window */

module.exports = RecordingVideoStreamMerger
function RecordingVideoStreamMerger(opts, text, rVideo, rScreenSharing, isCalculate = true, withAudio = true) {
  if (!(this instanceof RecordingVideoStreamMerger)) return new RecordingVideoStreamMerger(opts)

  opts = opts || {}

  const AudioContext = window.AudioContext || window.webkitAudioContext
  const audioSupport = !!(AudioContext && (this._audioCtx = (opts.audioContext || new AudioContext())).createMediaStreamDestination)
  const canvasSupport = !!document.createElement('canvas').captureStream
  const supported = audioSupport && canvasSupport
  if (!supported) {
    throw new Error('Unsupported browser')
  }
  this.rVideo = rVideo;
  this.rScreenSharing = rScreenSharing;
  this.width = opts.width || 640
  this.height = opts.height || 480
  this.fps = opts.fps || 20
  this.clearRect = opts.clearRect === undefined ? true : opts.clearRect
  this.videoWidth = 320;

  // Hidden canvas element for merging
  this._canvas = document.createElement('canvas')
  this._canvas.setAttribute('width', this.width)
  this._canvas.setAttribute('height', this.height)
  //this._canvas.setAttribute('style', 'position:fixed; left: 110%; pointer-events: none; top: 50%; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);') // Push off screen
  this._canvas.setAttribute('style', 'pointer-events: none; display: flex; justify-content: center; align-items: center;') // Push off screen
  this._ctx = this._canvas.getContext('2d')

  this._streams = []
  this._frameCount = 0

  this.isCalculate = isCalculate;

  this._audioDestination = this._audioCtx.createMediaStreamDestination()

  // delay node for video sync
  this._videoSyncDelayNode = this._audioCtx.createDelay(5.0)
  this._videoSyncDelayNode.connect(this._audioDestination)

  this.started = false
  this.result = null
  if (withAudio)
  {
    this._setupConstantNode() // HACK for wowza #7, #10
    this._backgroundAudioHack()
  }
  this._streamIdMap = new Map();
  this._text = text;
}

RecordingVideoStreamMerger.prototype.setOutputSize = function (width, height) {
  this.width = width
  this.height = height
  this._canvas.setAttribute('width', this.width)
  this._canvas.setAttribute('height', this.height)
}

RecordingVideoStreamMerger.prototype.calculateSize = function () {
  if (!this.started || !this.isCalculate)
    return;

  let total = 0;
  let streams = [];
  let screenStreams;
  let peerId = null;
  if (window.config.recording.showWebcamWhenShare.enable)
  {
    for (let i = 0; i < this._streams.length; i++) {
      if (this._streams[i].isScreen)
      {
        peerId = this._streams[i].peerId;
        break;
      } 
    }
  }
  

  for (let i = 0; i < this._streams.length; i++) {
    if (window.config.recordingMode === 'democratic')
    {
      if (this._streams[i].isScreen)
      {
        screenStreams = [];
        screenStreams.push(this._streams[i]);
        this._streams[i].addText = true;
        this._streams[i].index = 0;
        this._streams[i].isDraw = true;
      } 
      else
      {
        if (this._streams[i].hasVideo) 
        {
          total ++;
          this._streams[i].index = i + 1;
          if (peerId !== null && peerId === this._streams[i].peerId)
          {
            const cameraPercent = window.config.recording.showWebcamWhenShare.cameraPercent || 10;;
            const verticalAlign = window.config.recording.showWebcamWhenShare.verticalAlign || 'top';
            const horizontalAlign = window.config.recording.showWebcamWhenShare.horizontalAlign || 'right';
            const videoInfor = caculateWebcam(this.height, this.width, this._streams[i],verticalAlign, horizontalAlign, cameraPercent);

            this._streams[i].width = videoInfor.width;
            this._streams[i].height = videoInfor.height;
            this._streams[i].x = videoInfor.x;
            this._streams[i].y = videoInfor.y;
            this._streams[i].addText = false;
            this._streams[i].isDraw = true;
          }
          else
          {
            this._streams[i].width = 0;
            this._streams[i].height = 0;
            this._streams[i].x = 0;
            this._streams[i].y = 0;
            this._streams[i].addText = true;
            this._streams[i].isDraw = (peerId === null);     
          }
          streams.push(this._streams[i]);
        }
      }
    }
    else
    {
      if (this._streams[i].hasVideo) 
      {
        total ++;
        this._streams[i].isDraw = true;
        this._streams[i].addText = true;
        streams.push(this._streams[i]);
      }
    }
  }
  //videoList.sort((a,b) => (a.start - b.start));
  this._streams.sort((a,b) => (a.index - b.index));
  if (screenStreams && screenStreams.length > 0)
  {
    streams = screenStreams;
    total = 1;
  }

  let rate = this.width / this.height;

  if (streams.length > 0)
  {
    rate = streams[0].aspectRatio;
  }
  
  let rows = Math.round(Math.sqrt(total));
  let remain = total;

  let width;
  let height;

  let videoWidth = this.width;
  let videoHeight = this.height;

  if (total === 1)
  {
    height = videoHeight;
    width = Math.floor(rate * height);
    if (width > videoWidth)
    {
      width = videoWidth;
      height = Math.floor(width / rate);
    }
  }
  else
  {
    let number = Math.round(total / rows);
    if (number * rows < total)
      number ++;

    width = Math.floor(videoWidth / number);
    height = Math.floor(width / rate);
    if ((height * rows) > videoHeight)
    {
      height = Math.floor(videoHeight/ rows);
      width = height * rate;
    }
  }

  let top = 0;
  let left = 0;
  if (height * rows < this.height) {
    top = Math.floor((this.height - height * rows)/2);
  }

  if (total === 1)
  {
    left = Math.floor((videoWidth - width)/2);
  }
  
  let start = 0;
  let end = 0;
  for (let k = 0; k < rows; k++) {
    let number = Math.round(total / rows);

    if (number * rows < total)
      number ++;

    if (number > remain) 
    {
      number = remain;
      if (width * number < videoWidth) {
        left = Math.floor((videoWidth - width * number)/2);
      }
    }
    else
    {
      if (width * number < videoWidth) {
        left = Math.floor((videoWidth - width * number)/2);
      }
    }

    remain = remain - number;
    this.videoWidth = width;
    end = end + number;
    let col = 0;
    for (let j = start; j < end; j++) {
      let videoRate = streams[j].aspectRatio;
      if (videoRate === rate)
      {
        streams[j].width = width;
        streams[j].height = height;
        streams[j].x = col * width + left;
        streams[j].y = k * height + top;
      }
      else
      {
        let curHeight = height;
        let curWidth = Math.floor(videoRate * curHeight);
        if (curWidth > width)
        {
          curWidth = width;
          curHeight = Math.floor(curWidth / videoRate);
        }

        let curLeft = Math.floor((videoWidth - width * number)/2);
        let curTop = Math.floor((videoHeight - height * rows)/2);

        streams[j].width = curWidth;
        streams[j].height = curHeight;
        streams[j].x = col * width + curLeft + (width - curWidth)/2;
        streams[j].y = k * height + curTop + (height - curHeight)/2;
      }  
      col ++;
    }
    start = start + number;
  }
}

RecordingVideoStreamMerger.prototype.getAudioContext = function () {
  return this._audioCtx
}

RecordingVideoStreamMerger.prototype.getAudioDestination = function () {
  return this._audioDestination
}

RecordingVideoStreamMerger.prototype.getCanvasContext = function () {
  return this._ctx
}

RecordingVideoStreamMerger.prototype._backgroundAudioHack = function () {
  // stop browser from throttling timers by playing almost-silent audio
  const source = this._audioCtx.createConstantSource()
  const gainNode = this._audioCtx.createGain()
  gainNode.gain.value = 0.001 // required to prevent popping on start
  source.connect(gainNode)
  gainNode.connect(this._audioCtx.destination)
  source.start()
}

RecordingVideoStreamMerger.prototype._setupConstantNode = function () {
  const constantAudioNode = this._audioCtx.createConstantSource()
  constantAudioNode.start()

  const gain = this._audioCtx.createGain() // gain node prevents quality drop
  gain.gain.value = 0

  constantAudioNode.connect(gain)
  gain.connect(this._videoSyncDelayNode)
}

RecordingVideoStreamMerger.prototype.updateIndex = function (mediaStream, index) {
  if (typeof mediaStream === 'string') {
    mediaStream = {
      id: mediaStream
    }
  }

  index = index == null ? 0 : index

  for (let i = 0; i < this._streams.length; i++) {
    if (mediaStream.id === this._streams[i].id) {
      this._streams[i].index = index
    }
  }
  this._sortStreams()
}

RecordingVideoStreamMerger.prototype._sortStreams = function () {
  this._streams = this._streams.sort((a, b) => {
    return a.index - b.index
  })
}

// convenience function for adding a media element
RecordingVideoStreamMerger.prototype.addMediaElement = function (id, mediaStream, element, opts) {
  opts = opts || {}

  opts.x = opts.x || 0
  opts.y = opts.y || 0
  //opts.width = opts.width
  //opts.height = opts.height
  opts.mute = opts.mute || opts.muted || false

  opts.oldDraw = opts.draw
  opts.oldAudioEffect = opts.audioEffect

  if (element.tagName === 'VIDEO' || element.tagName === 'IMG') {
    opts.draw = (ctx, _, done) => {
      if (opts.oldDraw) {
        opts.oldDraw(ctx, element, done)
      } else {
        // default draw function
        const width = opts.width == null ? this.width : opts.width
        const height = opts.height == null ? this.height : opts.height
        ctx.drawImage(element, opts.x, opts.y, width, height)
        done()
      }
    }
  } else {
    opts.draw = null
  }

  if (!opts.mute) {
    const audioSource = element._mediaElementSource || this.getAudioContext().createMediaElementSource(element)
    element._mediaElementSource = audioSource // can only make one source per element, so store it for later (ties the source to the element's garbage collection)
    audioSource.connect(this.getAudioContext().destination) // play audio from original element

    const gainNode = this.getAudioContext().createGain()
    audioSource.connect(gainNode)
    if (element.muted) {
      // keep the element "muted" while having audio on the merger
      element.muted = false
      element.volume = 0.001
      gainNode.gain.value = 1000
    } else {
      gainNode.gain.value = 1
    }
    opts.audioEffect = (_, destination) => {
      if (opts.oldAudioEffect) {
        opts.oldAudioEffect(gainNode, destination)
      } else {
        gainNode.connect(destination)
      }
    }
    opts.oldAudioEffect = null
  }

  this.addStream({id: id, mediaStream: mediaStream, opts: opts})
}

RecordingVideoStreamMerger.prototype.addStream = function ({id, mediaStream, opts, clear = false, isScreen = false, displayName, peerId}) {
  if (clear) {
   this.clear();
  } else {
    this.removeStream(id);
  }
  if (typeof mediaStream === 'string') {
    return this._addData(mediaStream, opts);
  }
  opts = opts || {};
  const stream = {};

  stream.isData = false;
  stream.x = opts.x || 0;
  stream.y = opts.y || 0;
  stream.width = opts.width;
  stream.height = opts.height;
  stream.draw = opts.draw || null;
  stream.mute = opts.mute || opts.muted || false;
  stream.audioEffect = opts.audioEffect || null;
  stream.index = opts.index == null ? 0 : opts.index;
  stream.hasVideo = mediaStream.getVideoTracks().length > 0;
  stream.displayName = displayName;
  stream.peerId = peerId;
  if (stream.hasVideo)
  {
    let track = mediaStream.getVideoTracks()[0].getConstraints();
    if (track && track.width)
    {
      stream.width = track.width;
      stream.aspectRatio = track.aspectRatio;
    }
    else
    {
      track = mediaStream.getVideoTracks()[0].getSettings();
      if (track && track.width)
      {
        stream.width = track.width;
        stream.aspectRatio = track.aspectRatio;
      }
      else
      {
        if (isScreen)
        {
          stream.aspectRatio = this.rScreenSharing.aspectRatio;
          stream.width = this.rScreenSharing.width.ideal;
        }
        else
        {
          stream.aspectRatio = this.rVideo.aspectRatio;
          stream.width = this.rVideo.width.ideal;
        }
        
      }
      
    }
    stream.isDraw = true;
  }
  stream.isScreen = isScreen;

  // If it is the same MediaStream, we can reuse our video element (and ignore sound)
  let videoElement = null
  for (let i = 0; i < this._streams.length; i++) {
    if (this._streams[i].id === mediaStream.id) {
      videoElement = this._streams[i].element
    }
  }

  if (!videoElement) {
    videoElement = document.createElement('video')
    videoElement.autoplay = true
    videoElement.muted = true
    videoElement.srcObject = mediaStream
    videoElement.setAttribute('style', 'position:fixed; left: 5px; top:5px; pointer-events: none; opacity:0;')
    //videoElement.setAttribute('style', 'position: absolute; left: 5px; top:5px; pointer-events: none; opacity:0;')
    document.body.appendChild(videoElement)

    if (!stream.mute) {
      stream.audioSource = this._audioCtx.createMediaStreamSource(mediaStream)
      stream.audioOutput = this._audioCtx.createGain() // Intermediate gain node
      stream.audioOutput.gain.value = 1
      if (stream.audioEffect) {
        stream.audioEffect(stream.audioSource, stream.audioOutput)
      } else {
        stream.audioSource.connect(stream.audioOutput) // Default is direct connect
      }
      stream.audioOutput.connect(this._videoSyncDelayNode)
    }
    videoElement.play();
  }

  stream.element = videoElement
  stream.id = mediaStream.id || null
  this._streams.push(stream)
  this._sortStreams()
  this._streamIdMap.set(id, stream.id)
  if (stream.hasVideo) {
    this.calculateSize();
  }
}

RecordingVideoStreamMerger.prototype.removeStream = function (id) {
  const streamId = this._streamIdMap.get(id);
  if (!streamId || streamId === null)
    return;

  const mediaStream = {
    id: streamId
  }
  for (let i = 0; i < this._streams.length; i++) {
    const stream = this._streams[i]
    if (mediaStream.id === stream.id) {
      if (stream.audioSource) {
        stream.audioSource = null
      }
      if (stream.audioOutput) {
        stream.audioOutput.disconnect(this._videoSyncDelayNode)
        stream.audioOutput = null
      }
      if (stream.element) {
        stream.element.remove()
      }
      this._streams[i] = null
      this._streams.splice(i, 1)
      i--
      if (stream.hasVideo) {
        this.calculateSize();
      }
    }
  }
}

RecordingVideoStreamMerger.prototype._addData = function (key, opts) {
  opts = opts || {}
  const stream = {}

  stream.isData = true
  stream.draw = opts.draw || null
  stream.audioEffect = opts.audioEffect || null
  stream.id = key
  stream.element = null
  stream.index = opts.index == null ? 0 : opts.index

  if (stream.audioEffect) {
    stream.audioOutput = this._audioCtx.createGain() // Intermediate gain node
    stream.audioOutput.gain.value = 1
    stream.audioEffect(null, stream.audioOutput)
    stream.audioOutput.connect(this._videoSyncDelayNode)
  }

  this._streams.push(stream)
  this._sortStreams()
}

RecordingVideoStreamMerger.prototype.addText = function (text) {
  this._text = text;
}

// Wrapper around requestAnimationFrame and setInterval to avoid background throttling
RecordingVideoStreamMerger.prototype._requestAnimationFrame = function (callback) {
  let fired = false
  const interval = setInterval(() => {
    if (!fired && document.hidden) {
      fired = true
      clearInterval(interval)
      callback()
    }
  }, 1000 / this.fps)
  requestAnimationFrame(() => {
    if (!fired) {
      fired = true
      clearInterval(interval)
      callback()
    }
  })
}

RecordingVideoStreamMerger.prototype.start = function () {
  this.started = true
  this.calculateSize();
  this._requestAnimationFrame(this._draw.bind(this))

  // Add video
  this.result = this._canvas.captureStream(this.fps)

  // Remove "dead" audio track
  const deadTrack = this.result.getAudioTracks()[0]
  if (deadTrack) this.result.removeTrack(deadTrack)

  // Add audio
  const audioTracks = this._audioDestination.stream.getAudioTracks()
  this.result.addTrack(audioTracks[0])
}

RecordingVideoStreamMerger.prototype._updateAudioDelay = function (delayInMs) {
  this._videoSyncDelayNode.delayTime.setValueAtTime(delayInMs / 1000, this._audioCtx.currentTime)
}

RecordingVideoStreamMerger.prototype._draw = function () {
  if (!this.started) return

  this._frameCount++

  // update video processing delay every 60 frames
  let t0 = null
  if (this._frameCount % 60 === 0) {
    t0 = performance.now()
  }

  let awaiting = this._streams.length
  const done = () => {
    awaiting--
    if (awaiting <= 0) {
      if (this._frameCount % 60 === 0) {
        const t1 = performance.now()
        this._updateAudioDelay(t1 - t0)
      }
      this._requestAnimationFrame(this._draw.bind(this))
    }
  }

  if (this.clearRect) {
    this._ctx.clearRect(0, 0, this.width, this.height)
  }
  let videoCnt = 0;
  this._streams.forEach((stream) => {    
    if (stream.draw) { // custom frame transform
      stream.draw(this._ctx, stream.element, done);
      videoCnt ++;
    } else if (!stream.isData && stream.hasVideo && stream.isDraw) {
      // default draw function
      const width = stream.width == null ? this.width : stream.width;
      const height = stream.height == null ? this.height : stream.height;
      this._ctx.drawImage(stream.element, stream.x, stream.y, width, height);
      if (window.config.recording.addText.enable && stream.addText)
      {
        this._ctx.font = window.config.recording.addText.font;
        this._ctx.fillStyle = window.config.recording.addText.fontcolor;        
        this._ctx.fillText(stream.displayName, stream.x + 10, stream.y + height - 10);
      }
      videoCnt ++;
      done();
    } else {
      done();
    }
  })

  if (videoCnt === 0)
  {
    this._ctx.fillStyle = window.config.recording.textDraw.backgroundColor;
    this._ctx.fillRect(0, 0, this.width, this.height);
    this._ctx.font = window.config.recording.textDraw.font;
    this._ctx.fillStyle = window.config.recording.textDraw.fontcolor;
    this._ctx.textAlign = "center";
    this._ctx.fillText(this._text, this.width/2, this.height/2);
  }

  if (this._streams.length === 0) done()
}



RecordingVideoStreamMerger.prototype.clear = function () {
  try 
	{
    let audioStream;
    this._streams.forEach(stream => {
      if (!stream.mute) {
        audioStream = stream;
      } else {
        if (stream.element) {
          stream.element.remove()
        }
      }
    })
    this._streams = [];
    this._streamIdMap.clear();
    if (audioStream)
    {
      this._streams.push(audioStream);
      this._streamIdMap.set('AUDIO_KEY', audioStream);
    }
  }
  catch (error) {}  
}
RecordingVideoStreamMerger.prototype.destroy = function () {
  try 
	{
    this.started = false
    this._canvas = null
    this._ctx = null
    this._streams.forEach(stream => {
      if (stream.element) {
        stream.element.remove()
      }
    })
    this._streams = []
    this._audioCtx.close()
    this._audioCtx = null
    this._audioDestination = null
    this._videoSyncDelayNode = null

    this.result.getTracks().forEach((t) => {
      t.stop()
    })

    this.result = null
  }
  catch (error) 
	{}  
}
function caculateWebcam(mainVideoHeight, mainVideoWidth, stream, webcamVerticalAlign, webcamHorizontalAlign, cameraPercent)
{
  let x,y = 0;
  const webcamHeight = mainVideoHeight * cameraPercent/100;
  const webcamWidth = webcamHeight * stream.aspectRatio;

  if (webcamVerticalAlign === 'middle')
  {
    y = (mainVideoHeight - webcamHeight)/2;
  }
  else if (webcamVerticalAlign === 'bottom')
  {
    y = (mainVideoHeight - webcamHeight);
  }
  else
  {
    y = 0;
  }

  if (webcamHorizontalAlign === 'middle')
  {
    x = (mainVideoWidth - webcamWidth)/2;
  }
  else if (webcamHorizontalAlign === 'left')
  {
    x = 0;
  }
  else
  {
    x = (mainVideoWidth - webcamWidth);
  }
  return {x : x, y : y, height: webcamHeight, width: webcamWidth};
}
