export const actionPage = (data) => ({
  type: 'FLAG_ACTION_PAGE',
  payload: { data }
})

export const addDataWhiteboard = (
  data,
  slideChanged,
  slideIndexChanged,
  actionSyncObject,
  canvasProperty
) => ({
  type: 'DATA_WHITEBOARD',
  payload: {
    data,
    slideChanged,
    slideIndexChanged,
    actionSyncObject,
    canvasProperty
  }
})

export const grantPermissionWhiteboard = (data) => ({
  type: 'PERMISSION_WHITEBOARD',
  payload: { data }
})

export const openShareWhiteboard = (data) => ({
  type: 'OPEN_SHARE_WHITEBOARD',
  payload: { data }
})

export const openWhiteboard = () => ({
  type: 'OPEN_WHITEBOARD'
})

export const closeWhiteboard = () => ({
  type: 'CLOSE_WHITEBOARD'
})

export const clickTool = (tool) => ({
  type: 'CLICK_TOOL',
  payload: { tool }
})

export const selectTool = (tool) => ({
  type: 'SELECT_TOOL',
  payload: { tool }
})

export const selectDrawTool = (tool) => ({
  type: 'SELECT_DRAW_TOOL',
  payload: { tool }
})

export const selectLineWidth = (size) => ({
  type: 'SELECT_LINE_WIDTH',
  payload: { size }
})

export const selectFontBold = (status) => ({
  type: 'SELECT_FONT_BOLD',
  payload: { status }
})

export const selectFontItalic = (status) => ({
  type: 'SELECT_FONT_ITALIC',
  payload: { status }
})

export const selectFont = (type) => ({
  type: 'SELECT_FONT',
  payload: { type }
})

export const selectFontSize = (size) => ({
  type: 'SELECT_FONT_SIZE',
  payload: { size }
})

export const selectColor = (color) => ({
  type: 'SELECT_COLOR',
  payload: { color }
})

export const selectTextColor = (color) => ({
  type: 'SELECT_TEXT_COLOR',
  payload: { color }
})

export const selectLineColor = (color) => ({
  type: 'SELECT_LINE_COLOR',
  payload: { color }
})

export const nextSlide = (status) => ({
  type: 'NEXT_SLIDE',
  payload: {
    isNew: status
  }
})

export const syncNewSlide = () => ({
  type: 'SYNC_NEW_SLIDE'
})

export const previousSlide = () => ({
  type: 'PREVIOUS_SLIDE'
})

export const selectPage = (index) => ({
  type: 'SELECT_PAGE',
  payload: {
    index
  }
})

export const deleteSlide = (slide, position) => ({
  type: 'DELETE_SLIDE',
  payload: {
    slide,
    position
  }
})

export const importSlides = (slidesData) => ({
  type: 'IMPORT_SLIDES',
  payload: { ...slidesData }
})

export const setForceValue = (status) => ({
  type: 'SET_FORCE_VALUE',
  payload: { status }
})
