export function genTurn(turnServers, flag) {
    const iceServers = [];
    if (turnServers) {
        const turnCombinations = [
            { protocol: 'turn', transport: 'tcp' },
            { protocol: 'turn', transport: 'udp' },
          ];
          
        if (flag !== 'edge') {
            turnCombinations.push({ protocol: 'turns', transport: 'tcp' });
        }
        /*
        for (const turnServer of turnServers)
		{
            const stunServer = {
                urls: `stun:${turnServer.host}:${turnServer.port}`,
                url: `stun:${turnServer.host}:${turnServer.port}`,
                username: turnServer.username,
                credential: turnServer.credential,
            };
    
            iceServers.push(stunServer);
    
            for (const turnType of turnCombinations) {
                const protocol = turnType.protocol;
                const transport = turnType.transport;
      
                const iceServer = {
                  urls: `${protocol}:${turnServer.host}:${turnServer.port}?transport=${transport}`,
                  url: `${protocol}:${turnServer.host}:${turnServer.port}?transport=${transport}`,
      
                  username: turnServer.username,
                  credential: turnServer.credential,
                };
                iceServers.push(iceServer);
            }
        }
        */
       for (const turnServer of turnServers)
		{
            const stunServer = {
                urls: `stun:${turnServer.host}`,
                url: `stun:${turnServer.host}`,
                username: turnServer.username,
                credential: turnServer.credential,
            };
    
            iceServers.push(stunServer);
    
            for (const turnType of turnCombinations) {
                const protocol = turnType.protocol;
                const transport = turnType.transport;
                let iceServer;
                if (protocol === 'turns' || transport === 'tcp')
                {
                    iceServer = {
                    urls: `${protocol}:${turnServer.host}:${turnServer.port}?transport=${transport}`,
                    url: `${protocol}:${turnServer.host}:${turnServer.port}?transport=${transport}`,        
                    username: turnServer.username,
                    credential: turnServer.credential,
                    };
                }
                else
                {
                    iceServer = {
                        urls: `${protocol}:${turnServer.host}?transport=${transport}`,
                        url: `${protocol}:${turnServer.host}?transport=${transport}`,
            
                        username: turnServer.username,
                        credential: turnServer.credential,
                      };
                }
                
                iceServers.push(iceServer);
            }
        }
    }
    return iceServers;
}