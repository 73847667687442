import axios from 'axios';
import {getCookie} from './utils';

export const JOIN_ACTION = 'join';
export const LEAVE_ACTION = 'leave';
export const END_ACTION = 'end';
export const INIT_ACTION = 'init';
export const CONFIRM_PASSWORD_ACTION = 'confirmPassword';
export const GET_MEETING_INFOR = 'get';

export const MODERATOR = 'moderator';
export const NORMAL = 'normal';
export const GUEST = 'guest';
export const CUSTOMER = 'customer';


export const METTING_STATUS_CANCELED = 'canceled';
export const METTING_STATUS_FINISHED = 'finished';
export const METTING_STATUS_STARTED = 'started';
export const METTING_STATUS_WAITTING = 'waitting';
const apiUrl = window.config.apiUrl || '';

export function actionGetMeetingInfor(param, action ){
    const result = axios({
        method: 'post',
        url: `${apiUrl}/api/meetings/${action}`,
        data: param
    })
    return result;
}

export function actionParticipantsMeeting(param, action ){
    const result = axios({
        method: 'post',
        url: `${apiUrl}/api/participants/${action}`,
        data: param
    })
    return result;
}

export function actionGuestMeeting(param, action ){
    const result = axios({
        method: 'post',
        url: `${apiUrl}/api/guest/${action}`,
        data: param
    })
    return result;
}

export function actionHostMeeting(param, action ){
    const result = axios({
        method: 'post',
        url: `${apiUrl}/api/meetings/${action}`,
        data: param,
        headers: { "Authorization": "bearer "+ (getCookie('idToken') || '7bcf8969-0d77-4a0c-b41f-94c0c7a73241') }
    })
    return result;
}
