export function getSignalingUrl(peerId, roomId, role, password)
{
	if (!role)
	{
		role = 'normal';
	}
	if (!password)
	{
		password = '';
	}
	const serverUrl = window.config.signalingUrl || `wss://${window.location.hostname}`;
	const url = `${serverUrl}/?peerId=${peerId}&roomId=${roomId}&role=${role}`;
	return url;
}