import {
  DrawTools,
  Tools,
  COLOR_INFO,
  actionOnPageHeader
} from '../components/Whiteboard/Constants'

const initialState = {
  permissionWhiteboard: null,
  isOpenShareWhiteboard: false,
  getSlideIndexChanged: null,
  getSlideChanged: '',
  actionSyncObject: '',
  actionOnPage: null,
  ratioObj: null,
  dataWhiteboard: null,
  whiteboardOpen: false,
  clickedTool: null,
  selectedTool: Tools.DRAW,
  drawSelectedTool: DrawTools.LINE,
  selectedTextColor: COLOR_INFO[0].color,
  selectedColor: COLOR_INFO[0].color,
  selectedLineColor: COLOR_INFO[0].color,
  selectedLineWidth: 5,
  selectedFont: null,
  selectedFontBold: '',
  selectedFontItalic: '',
  selectedFontSize: 30,
  previousSlide: 0,
  currentSlide: 0,
  slides: ['slide-0'],
  totalSlides: 1,
  currentSlideName: 'slide-0',
  forceValue: false
}

const whiteboard = (state = initialState, action) => {
  switch (action.type) {
    case 'FLAG_ACTION_PAGE': {
      return { ...state, actionOnPage: action.payload.data }
    }
    case 'DATA_WHITEBOARD': {
      return {
        ...state,
        dataWhiteboard: action.payload.data,
        getSlideChanged: action.payload.slideChanged,
        getSlideIndexChanged: action.payload.slideIndexChanged,
        actionSyncObject: action.payload.actionSyncObject,
        canvasProperty: action.payload.canvasProperty
      }
    }

    case 'PERMISSION_WHITEBOARD': {
      const { data } = action.payload
      return {
        ...state,
        permissionWhiteboard: {
          peerId: data.peerId,
          role: data.role
        }
      }
    }

    case 'OPEN_SHARE_WHITEBOARD': {
      const { data } = action.payload
      return { ...state, isOpenShareWhiteboard: data }
    }

    case 'OPEN_WHITEBOARD': {
      const whiteboardOpen = true
      return { ...state, whiteboardOpen }
    }
    case 'CLOSE_WHITEBOARD': {
      const whiteboardOpen = false
      return { ...state, whiteboardOpen }
    }
    case 'CLICK_TOOL': {
      const { tool } = action.payload
      return { ...state, clickedTool: tool }
    }
    case 'SELECT_TOOL': {
      const { tool } = action.payload
      return { ...state, selectedTool: tool }
    }
    case 'SELECT_DRAW_TOOL': {
      const { tool } = action.payload
      return { ...state, drawSelectedTool: tool }
    }
    case 'SELECT_FONT_BOLD': {
      const { status } = action.payload
      return { ...state, selectedFontBold: status }
    }
    case 'SELECT_FONT_ITALIC': {
      const { status } = action.payload
      return { ...state, selectedFontItalic: status }
    }
    case 'SELECT_FONT': {
      const { type } = action.payload
      return { ...state, selectedFont: type }
    }
    case 'SELECT_LINE_WIDTH': {
      const { size } = action.payload
      return { ...state, selectedLineWidth: size }
    }
    case 'SELECT_FONT_SIZE': {
      const { size } = action.payload
      return { ...state, selectedFontSize: size }
    }
    case 'SELECT_TEXT_COLOR': {
      const { color } = action.payload
      return { ...state, selectedTextColor: color }
    }
    case 'SELECT_COLOR': {
      const { color } = action.payload
      return { ...state, selectedColor: color }
    }
    case 'SELECT_LINE_COLOR': {
      const { color } = action.payload
      return { ...state, selectedLineColor: color }
    }
    case 'SYNC_NEW_SLIDE': {
      return {
        ...state,
        currentSlide: state.currentSlide,
        slides: [...state.slides, `slide-${state.totalSlides}`],
        totalSlides: state.totalSlides + 1
      }
    }
    case 'NEXT_SLIDE': {
      const { isNew } = action.payload
      if (isNew) {
        let newSlideIndex =
          parseInt(state.slides[state.slides.length - 1].split('-')[1]) + 1
        if (state.totalSlides === 1) newSlideIndex = 1
        return {
          ...state,
          actionOnPage: actionOnPageHeader.NEW_NEXT_PAGE,
          previousSlide: state.currentSlide,
          currentSlide: state.currentSlide + 1,
          slides: [...state.slides, `slide-${newSlideIndex}`],
          totalSlides: state.totalSlides + 1,
          currentSlideName: `slide-${state.totalSlides}`
        }
      }
      return {
        ...state,
        actionOnPage: actionOnPageHeader.NEXT_PAGE,
        previousSlide: state.currentSlide,
        currentSlide: state.currentSlide + 1,
        currentSlideName: state.slides[state.currentSlide + 1]
      }
    }
    case 'PREVIOUS_SLIDE': {
      return {
        ...state,
        actionOnPage: actionOnPageHeader.PREVIOUS_PAGE,
        previousSlide: state.currentSlide,
        currentSlide: state.currentSlide - 1,
        currentSlideName: state.slides[state.currentSlide - 1]
      }
    }
    case 'SELECT_PAGE': {
      return {
        ...state,
        previousSlide: state.currentSlide,
        currentSlide: action.payload.index,
        currentSlideName: state.slides[action.payload.index]
      }
    }
    case 'DELETE_SLIDE': {
      const { currentSlide } = state
      const { position } = action.payload
      const newSlides = state.slides.filter(
        (ele) => ele !== state.slides[position]
      )

      let newCurrentSlide = currentSlide

      if (position === currentSlide) {
        newCurrentSlide = currentSlide !== 0 ? currentSlide - 1 : currentSlide
      } else {
        if (action.payload.position < currentSlide) {
          newCurrentSlide = newCurrentSlide - 1
        } else {
        }
      }
      return {
        ...state,
        actionOnPage: actionOnPageHeader.DELETE_PAGE,
        slides: [...newSlides],
        currentSlide: newCurrentSlide,
        currentSlideName: newSlides[newCurrentSlide]
      }
    }
    case 'IMPORT_SLIDES': {
      const { slides } = action.payload
      return {
        ...state,
        slides: [...slides],
        previousSlide: 0,
        currentSlide: 0,
        totalSlides: slides.length,
        currentSlideName: slides[0],
        forceValue: true
      }
    }
    case 'SET_FORCE_VALUE': {
      const { status } = action.payload
      return {
        ...state,
        forceValue: status
      }
    }
    default:
      return state
  }
}

export default whiteboard
