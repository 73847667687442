import * as bodyPix from '@tensorflow-models/body-pix';
//import * as StackBlur from 'stackblur-canvas';
// import * as tf from '@tensorflow/tfjs';
import {
    CLEAR_TIMEOUT,
    TIMEOUT_TICK,
    SET_TIMEOUT,
    timerWorkerScript
} from './TimerWorker';

const segmentationProperties = {
    internalResolution: 'medium',
    segmentationThreshold: 0.7,
    effect:'bokeh'
  };
// let _maskFrameTimerWorker;
/**
 * Represents a modified MediaStream that adds blur to video background.
 * <tt>StreamBlurEffect</tt> does the processing of the original
 * video stream.
 */
export default class StreamBlurEffect {
    _bpModel: Object;
    _inputVideoElement: HTMLVideoElement;
    _onMaskFrameTimer: Function;
    _maskInProgress: boolean;
    _outputCanvasElement: HTMLCanvasElement;
    _renderMask: Function;
    _segmentationData: Object;
    _maskFrameTimerWorker: Object;
    isEnabled: Function;
    startEffect: Function;
    stopEffect: Function;
    setIsBlur: Function;
    isStartEffect: Function;

    /** 
     * Represents a modified video MediaStream track.
     *
     * @class
     * @param {BodyPix} bpModel - BodyPix model.
     */
    constructor(bpModel: Object) {
        this._bpModel = bpModel;
        this._onMaskFrameTimer = this._onMaskFrameTimer.bind(this);
        this.count = 0;
        this.isBlur = false;
        this.isStarted = false;
        this._inputVideoElement = null;
        this._outputCanvasElement = null;
        this._maskFrameTimerWorker = null;

    }

    /**
     * EventHandler onmessage for the maskFrameTimerWorker WebWorker.
     *
     * @private++
     * @param {EventHandler} response - The onmessage EventHandler parameter.
     * @returns {void}
     */
    async _onMaskFrameTimer(response: Object) {
        if (response.data.id === TIMEOUT_TICK) {
            await this._renderMask(this);
        }
    }

    /**
     * Loop function to render the background mask.
     *
     * @private
     * @returns {void}
     */
    async _renderMask() {

        if (!this.isStarted)
            return;

        const videoObj = document.getElementById(this._id);
        if (videoObj !== this._outputCanvasElement)
        {
            await this.replaceTrack();
        }

        if (!this._maskInProgress && this.isBlur) {
            this._maskInProgress = true;
            this._bpModel.segmentPerson(this._inputVideoElement
                , segmentationProperties
                ).then(data => {
                this._segmentationData = data;
                this._maskInProgress = false;
            });
        }

        let backgroundBlurAmount = 10;
        let edgeBlurAmount = 3;
        const flipHorizontal = false;
        if ((this._segmentationData || !this.isBlur))  {
            if (!this._segmentationData || this._segmentationData.length === 0 || !this.isBlur)
            {
                this._segmentationData = [];
                backgroundBlurAmount = 0;
                edgeBlurAmount = 0;
            }
            try {
                bodyPix.drawBokehEffect(
                    this._outputCanvasElement, this._inputVideoElement, this._segmentationData, backgroundBlurAmount,
                    edgeBlurAmount, flipHorizontal);
            } catch (error) {
                console.log(error);
            }
        }
     
        this._maskFrameTimerWorker.postMessage({
            id: SET_TIMEOUT,
            timeMs: 1000 / 30
        });
    }

    isStartEffect()
    {
        return this.isStarted;
    }

    setIsBlur(flag)
    {
        this.isBlur = flag;
    }
    async replaceTrack()
    {
        if (this._id !== null)
        {
            this._outputCanvasElement = document.getElementById(this._id);
        }
        if (global.CLIENT._webcamProducer && global.CLIENT._webcamProducer !== null && this.isBlur)
        {
            const stream = this._outputCanvasElement.captureStream(parseInt(this._frameRate, 20));
            let track;
            ([ track ] = stream.getVideoTracks());
            await global.CLIENT._webcamProducer.replaceTrack({ track: track });
        }
        else if (global.CLIENT._webcamProducer && global.CLIENT._webcamProducer !== null)
        {
            const stream = await global.CLIENT.getVideoStream();
            let track;
            ([ track ] = stream.getVideoTracks());
            await global.CLIENT._webcamProducer.replaceTrack({ track: track });
            
        }
    }
    
    /**
     * Starts loop to capture video frame and render the segmentation mask.
     *
     * @param {MediaStream} stream - Stream to be used for processing.
     * @returns {MediaStream} - The stream with the applied effect.
     */
    async startEffect(track, id = 'canvasVideo', isBlur = true) {
        if (id === null)
        {
            this._outputCanvasElement = document.createElement('canvas')
        }
        else
        {
            this._outputCanvasElement = document.getElementById(id);
        }           
        if (!track && !this._outputCanvasElement)
        {
            return;
        }
        this.isStarted = true;
        this.isBlur = isBlur;
        this.count = 0;
        this._segmentationData = null;
        this._maskFrameTimerWorker = new Worker(timerWorkerScript, { name: 'Blur effect worker' });
        this._maskFrameTimerWorker.onmessage = this._onMaskFrameTimer;

        const { height, frameRate, width, aspectRatio }
            = track.getSettings().height ? track.getSettings() : track.getConstraints();
        const mHeight = height ? height: width/aspectRatio;
        const stream = new MediaStream();
        stream.addTrack(track);

        this._outputCanvasElement.width = parseInt(width, 10);
        this._outputCanvasElement.height = parseInt(mHeight, 10);
        this._inputVideoElement = document.createElement('video');
        this._inputVideoElement.width = parseInt(width, 10);
        this._inputVideoElement.height = parseInt(mHeight, 10);
        this._inputVideoElement.autoplay = true;
        this._inputVideoElement.setAttribute('playsinline', '');
        this._inputVideoElement.muted = true;
        this._inputVideoElement.srcObject = stream;
        this._inputVideoElement.onloadeddata = () => {
            if (this._maskFrameTimerWorker !== null && this.isStarted)
            {
                this._maskFrameTimerWorker.postMessage({
                    id: SET_TIMEOUT,
                    timeMs: 1000 / 30
                });
            }
        };
       this._frameRate = frameRate;
       this._id = id;
       await this._inputVideoElement.play();
       this.replaceTrack();
       return this._outputCanvasElement.captureStream(parseInt(frameRate, 20));
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
     */
    async stopEffect()
    {
        this.isStarted = false;
        await this.replaceTrack();
        if (this._maskFrameTimerWorker) {
            this._maskFrameTimerWorker.postMessage({
                id: CLEAR_TIMEOUT
            });
    
            this._maskFrameTimerWorker.terminate();
            this._maskFrameTimerWorker = null;
        }

        if (this._outputCanvasElement)
        {
            this._outputCanvasElement.getContext('2d').clearRect(0, 0, this._outputCanvasElement.width, this._outputCanvasElement.height);
            this._outputCanvasElement = null;
        }

        if (this._inputVideoElement)
        {
            this._inputVideoElement.pause();
            this._inputVideoElement.srcObject = null;
            this._inputVideoElement = null;
        }
        this.count = 0;
        this.isBlur = false;
    }

    getOutputCanvasElement()
    {
        return this._outputCanvasElement;
    }
}