export const API_URL = 'https://akameetapitest.japaneast.cloudapp.azure.com';

export const RightPanel = {
  USERS: 'users',
  CHAT: 'chat',
  FILES: 'files',
  WHITEBOARD: 'whiteboard'
}

export const OnOffState = {
  ON: 'on',
  OFF: 'off'
}

export const LayoutMode = {
  DEMOCRATIC: 'democratic',
  FILMSTRIP: 'filmstrip'
}

export const SettingTab = {
  MEDIA: 'media',
  APPEARANCE: 'appearance',
  ADVANCED: 'advanced'
}