import domready from 'domready';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route, HashRouter, BrowserRouter } from 'react-router-dom';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import isElectron from 'is-electron';
import { detectDevice } from 'mediasoup-client';
import debug from 'debug';
import randomString from 'random-string';
import Logger from './Logger';
import RoomClient from './RoomClient';
import RoomContext from './RoomContext';
import deviceInfo from './deviceInfo';
import recordInfo from './recordInfo';
import * as meActions from './actions/meActions';
import UnsupportedBrowser from './components/UnsupportedBrowser';
//import ChooseRoom from './components/ChooseRoom';
import NotFoundComponent from './components/NotFoundComponent';
import LoadingView from './components/LoadingView';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './store';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import { ReactLazyPreload } from './components/ReactLazyPreload';
import messagesJapanese from './translations/ja';
import messagesEnglish from './translations/en';
import messagesViennamese from './translations/vn';
import { MODERATOR, GUEST, CUSTOMER, NORMAL} from './utils/apiUtils';
import './index.css';
import axios from 'axios';
axios.defaults.withCredentials = true;

const App = ReactLazyPreload(() =>
  import(/* webpackChunkName: "app" */ './components/App')
);

const cache = createIntlCache();

const messages = {
  en: messagesEnglish,
  ja: messagesJapanese,
  vn: messagesViennamese
};

const supportedBrowsers = {
  windows: {
    'internet explorer': '>12',
    'microsoft edge': '>18',
  },
  safari: '>12',
  firefox: '>=60',
  chrome: '>=74',
  chromium: '>=74',
  opera: '>=62',
  'samsung internet for android': '>=11.1.1.52',
};

const browserLanguage = (
  navigator.language || navigator.browserLanguage
).toLowerCase();

let locale = browserLanguage.split(/[-_]/)[0]; // language without region code
let _message = messages['en']
if (window.config.language && window.config.language !== 'none')
{
	locale = window.config.language;
	_message = messages[locale]
}
else
{
	if (messages[locale])
	_message = messages[locale];
}

const intl = createIntl(
  {
    locale,
    messages: _message,
  },
  cache
);

document.documentElement.lang = locale;

if (
  process.env.REACT_APP_DEBUG === '*' ||
  process.env.NODE_ENV !== 'production'
) {
  debug.enable('* -engine* -socket* -RIE* *WARN* *ERROR*');
}

const logger = new Logger();

let roomClient;

const theme = createMuiTheme(window.config.theme);

let Router;

if (isElectron()) Router = HashRouter;
else Router = BrowserRouter;

domready(() => {
  run();
});

const baseUrl = window.location;

function run() {
	logger.debug('run() [environment:%s]', process.env.NODE_ENV);
	logger.debug('run() [environment:%s] [locale:%s]', process.env.NODE_ENV, locale);
	const urlParser = new URL(window.location);	
	const parameters = urlParser.searchParams;
	const accessCode = parameters.get('code');
	let produce = parameters.get('produce') !== 'false';
	const forceTcp = parameters.get('forceTcp') === 'true';
	let displayName = parameters.get('displayName');
	let id = window.location.pathname.split('/').pop();	
	let userType;
	
	if (displayName && displayName !== '')
	{
		window.config.lockDisplayName = true;
	}
	const muted = parameters.get('muted') === 'true';
	let peerId = randomString({ length: 8 }).toLowerCase();

	if (urlParser.pathname.includes('/s/') && id) {
		userType = MODERATOR;		
	} else if (urlParser.pathname.includes('/g/') && id) {
		userType = GUEST;
	} else if (urlParser.pathname.includes('/j/') && id) {
		userType = CUSTOMER;
	} else {
		userType = NORMAL;
	}
	
	let basePath = window.config.baseAppUrl || '/conference';
	if (!basePath)
		basePath = '/';

	// Get current device.
	const device = deviceInfo();

	const recordInfor = recordInfo();

	RoomClient.init({ store, intl , recordInfor, baseUrl});

	let unsupportedBrowser = false;

	let webrtcUnavailable = false;

	if (detectDevice() === undefined) {
		logger.error('Your browser is not supported [deviceInfo:"%o"]', device);
		unsupportedBrowser = true;
	}
	else if (
		navigator.mediaDevices === undefined ||
		navigator.mediaDevices.getUserMedia === undefined ||
		window.RTCPeerConnection === undefined
	) {
		logger.error('Your browser is not supported [deviceInfo:"%o"]', device);
		webrtcUnavailable = true;
	}
	else if (
		!device.bowser.satisfies(
			window.config.supportedBrowsers || supportedBrowsers
		)
	) {
		logger.error(
			'Your browser is not supported [deviceInfo:"%o"]',
			device
		);

		unsupportedBrowser = true;
	}
	else {
		logger.debug('Your browser is supported [deviceInfo:"%o"]', device);
	}

	if (unsupportedBrowser || webrtcUnavailable) {
		render(
			<MuiThemeProvider theme={theme}>
				<RawIntlProvider value={intl}>
					<UnsupportedBrowser
						webrtcUnavailable={webrtcUnavailable}
						platform={device.platform}
					/>
				</RawIntlProvider>
			</MuiThemeProvider>,
			document.getElementById('akaMeet-conference')
		);

		return;
	}

	store.dispatch(
		meActions.setMe({
			peerId,
			loginEnabled: window.config.loginEnabled
		})
	);

	roomClient = new RoomClient(
		{
			peerId,
			accessCode,
			device,
			produce,
			forceTcp,
			displayName,
			muted,
			basePath,
			userType
		});

	global.CLIENT = roomClient;
	//<Route exact path='/:id' component={App} />
	render(
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<RawIntlProvider value={intl}>
					<PersistGate loading={<LoadingView />} persistor={persistor}>
						<RoomContext.Provider value={roomClient}>
							<SnackbarProvider>
								{/** basename={basePath} */}
								<Router basename={basePath}>
									<Suspense fallback={<LoadingView />}>
										<React.Fragment>
										<Switch>
											<Route path='/room/:id' component={App} />
											<Route path='/j/:id' component={App} />
											<Route path='/s/:id' component={App} />
											<Route path='/g/:id' component={App} />
											<Route exact path='/' component={App} />
											<Route component={NotFoundComponent} />
										</Switch>
										</React.Fragment>
									</Suspense>
								</Router>
							</SnackbarProvider>
						</RoomContext.Provider>
					</PersistGate>
				</RawIntlProvider>
			</MuiThemeProvider>
		</Provider>,
		document.getElementById('akaMeet-conference')
	);
}

serviceWorker.unregister();
