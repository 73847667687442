import { parseFullName } from 'parse-full-name';
import isEmpty from 'lodash/isEmpty';

export const toShortName = (value) => {
  var name = parseFullName(value);
  if (!isEmpty(name.last) && !isEmpty(name.first)) {
    return name.last.substr(0, 1) + name.first.substr(0, 1).toUpperCase();
  }
  if (!isEmpty(name.last)) {
    return name.last.substr(0, 2).toUpperCase();
  }
  if (!isEmpty(name.first)) {
    return name.last.substr(0, 2).toUpperCase();
  }
  return value;
};

export const setCookie = (cookieName, cookieValue, expiresHour) => {
  const d = new Date();
  d.setTime(d.getTime() + expiresHour * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

export const getCookie = (cookieName) => {
  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
  if (match) return match[2];
  return '';
};

export const decodeHTML = (html) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.innerHTML;
};